<script setup>
import { defineEmits } from 'vue';

const emit = defineEmits(['cancel-registration']);

const cancelRegistration = () => emit('cancel-registration');
</script>
<template>
<div
  class="
    text-black
    text-[1.85rem] xl:text-[1.75rem]
    font-AvenirLight
    pl-[1rem] xl:pl-[4.5rem]
    pr-[1rem]
  "
>
  <p class="text-[1.8125rem] xl:text-[2.25rem] pb-[3rem]">Can't make it?
    <span class="whitespace-nowrap">Change of plans?</span>
  </p>
  <p class="text-[1.8125rem] xl:text-[2.25rem] pb-[5rem]">No problem.</p>
  <p class="text-[1.125rem] xl:pb-[1.5rem]">Click below to confirm your cancellation.</p>
  <button
    class="
      uppercase
      w-[20rem]
      h-[4rem]
      bg-acura-primary
      text-white
      text-[1.25rem]
      font-AvenirHeavy
    "
    @click="cancelRegistration"
  >Cancel Registration</button>
</div>
</template>
