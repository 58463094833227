/* eslint-disable max-classes-per-file */
/**
 * Generates a parameter string to ensure fresh data is returned, if desired.
 * @param {boolean} cached Whether to retrieved cached data or not
 * @returns {string} empty string if cached, a unique identifier if not wanting cached data
 */
export const getCachedParams = (cached) => (cached ? '' : `?unique=${Math.round(+new Date() / 1000)}`);

export class baseAPI {
  constructor(axiosObject, apiBaseName) {
    this.axiosObject = axiosObject;
    this.apiBaseName = apiBaseName;

    this.getCachedParams = getCachedParams;
  }

  setAuthHeader(token) {
    this.axiosObject.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}

export class baseReadOnlyAPI extends baseAPI {
  async getAll(cached) {
    const { data } = await this.axiosObject.get(`${this.apiBaseName}${getCachedParams(cached)}`);
    return data;
  }

  async get(id, cached) {
    const { data } = await this.axiosObject.get(`${this.apiBaseName}/${id}${getCachedParams(cached)}`);
    return data;
  }
}

export class baseCRUDAPI extends baseReadOnlyAPI {
  async post(objectData) {
    const { data } = await this.axiosObject.post(this.apiBaseName, objectData);
    return data;
  }

  async put(objectData) {
    const { data } = await this.axiosObject.put(`${this.apiBaseName}`, objectData, {
      headers: {
        'Content-type': 'application/json',
      },
    });
    return data;
  }

  async patch(objectData) {
    const { data } = await this.axiosObject.patch(this.apiBaseName, objectData);
    return data;
  }

  async delete(id) {
    const { data } = await this.axiosObject.delete(`${this.apiBaseName}/${id}`);
    return data;
  }
}
