<template>
  <div class="relative pb-[56.25%] h-0">
    <iFrame
      class="absolute w-full h-full"
      :src="'https://player.vimeo.com/video/750035983?h=de374162cc?controls=1&autoplay='+ type +'&loop=0&byline=0&title=0&muted=' + type"
      title="Acura video Performance Drive"
      frameborder="0"
      webkitallowfullscreen mozallowfullscreen allowfullscreen
    />
  </div>
</template>
<script>
export default {
  name: 'SizzleReel',
  data() {
    return {
      isMobile: window.innerWidth <= 992,
      type: 0,
    };
  },
  created() {
    if (!this.isMobile) {
      this.type = 1;
    }
  },
};
</script>
