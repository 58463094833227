<template>
  <div class="
    section
    pb-8
    px-4
    lg:px-24
    lg:bg-[url('assets/img/section-dot-pattern-left.svg')]
    lg:bg-no-repeat
    lg:bg-left-bottom
  ">
    <div class="
      container
      mx-auto
      lg:mb-16
      bg-black
      bg-[url('assets/img/overview-dot-pattern.svg')]
      bg-no-repeat
      bg-right-bottom
      grid
      grid-cols-3
      border-t-2
      border-b-[3px]
      border-acura-primary
    ">
      <div class="col-span-full xl:col-span-2">
        <SizzleReel />
      </div>
      <div class="col-span-full xl:col-span-1 flex items-center">
        <div class="font-AcuraBespokeRegular my-8 mx-10">
          <h2 class="
            text-white
            text-4xl
            lg:text-5xl
            uppercase
            mb-8"
            aria-label="Acura Overview"
            >
              Overview
            </h2>
          <p class="text-white text-lg mb-8">
            {{ paragraphFilter }}
          </p>
          <router-link to="/event-information"
            class="
              p-4
              uppercase
              text-white
              bg-acura-primary
              font-AvenirHeavy"
              aria-label="Event Information page"
            >
              Learn More
            </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store';
import SizzleReel from '../sizzleReel/SizzleReel.vue';

export default {
  components: { SizzleReel },
  name: 'OverviewSection',
  componentes: {
    SizzleReel,
  },
  data() {
    return {
      paragraph: {
        cc: {
          text: "It's your time to take over the driver’s seat and become one with the machine. Experience the high-performance life. Put the all-new 2023 Integra through the paces. Encounter the power and precision of the Type S models TLX and MDX.",
        },
        vip: {
          text: "Enjoy an exclusive event for VIP Acura enthusiasts. It's your time to take over the driver’s seat and become one with the machine. Experience the high-performance life. Put the all-new 2023 Integra through the paces. Encounter the power and precision of the Type S models TLX and MDX.",
        },
        dealer: {
          text: 'Telling compelling product stories is the core of successful sales. And there’s no better place to experience Acura Precision Crafted Performance than in its natural habitat—the performance course. Acura Performance Drive offers you an immersive training environment where you can fully encounter the thrill of Acura Performance vehicles.',
        },
        media: {
          text: "Preview the Saturday consumer experience with exclusive early access for Media. It's your time to take over the driver’s seat and become one with the machine. Experience the high-performance life. Put the all-new 2023 Integra through the paces. Encounter the power and precision of the Type S models TLX and MDX.",
        },
        enthusiast: {
          text: 'As an NSX Club Member, this Acura & Coffee event is your exclusive opportunity to chat with AHM executives, as well as take over the driver’s seat of the TLX and MDX Type S models and the all-new 2023 Integra! Become one with these machines as you put them through their paces on custom configured closed courses. See how you stack up in our Integra autocross challenge. We look forward to meeting you!',
        },
      },
    };
  },
  computed: {
    paragraphFilter() {
      switch (true) {
        case store.getters['user/isDealer']: return this.paragraph.dealer.text;
        case store.getters['user/isMedia']: return this.paragraph.media.text;
        case store.getters['user/isVIP']: return this.paragraph.vip.text;
        case store.getters['user/isConsumer']: return this.paragraph.cc.text;
        case store.getters['user/isEnthusiast']: return this.paragraph.enthusiast.text;
        default: return this.paragraph.cc.text;
      }
    },
  },
};
</script>
