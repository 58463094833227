const getLocations = (data) => {
  const locationsWithDuplicates = data
    .flatMap((x) => x.locationModel)
    .map(({ locationId, city }) => ({ value: locationId, label: city }));
  const uniqueLocations = locationsWithDuplicates.reduce(
    (acc, x) => (acc.some((y) => y.value === x.value) ? acc : [...acc, x]),
    [],
  );
  return uniqueLocations;
};

const getInviteeTypes = (data) => data.map(
  ({ inviteeType, inviteeTypeName }) => ({ value: inviteeType, label: inviteeTypeName }),
);

const getDates = (data) => {
  const unique = [...new Set(data.flatMap((x) => x.locationModel).flatMap((x) => x.dates))];
  return unique.map((x) => `${x} 00:00`);
};

const filterByLocationId = (locationId) => (data) => data.map(
  (x) => ({
    ...x,
    locationModel: x.locationModel.filter((y) => y.locationId === locationId),
  }),
).filter((y) => y.locationModel.length > 0);

const filterByInviteeType = (inviteeType) => (data) => data.filter(
  (x) => x.inviteeType === inviteeType,
);

export {
  getLocations,
  getInviteeTypes,
  getDates,
  filterByLocationId,
  filterByInviteeType,
};
