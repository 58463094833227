import axios from 'axios';

import Home from './home';
import Reservations from './reservations';
import CheckIn from './checkIn';
import Print from './print';
import Report from './report';

const success = (response) => response;
const failure = (error) => Promise.reject(error);

class ApiManager {
  constructor() {
    this.requiresAuth = false;
    // Core api for project
    this.coreObject = axios.create({
      baseURL: process.env.VUE_APP_BASE_API_APP_URL,
    });

    this.coreObject.interceptors.response.use(success, failure);

    this.home = new Home(this.coreObject);
    this.reservations = new Reservations(this.coreObject);
    this.checkIn = new CheckIn(this.coreObject);
    this.print = new Print(this.coreObject);
    this.report = new Report(this.coreObject);
  }

  /**
   * Add any APIs that require authentication to the array within method.
   */
  setAuthHeader(authToken) {
    [
      this.home,
      this.reservations,
      this.checkIn,
      this.print,
      this.report,
      // add API needing authentication here
    ].forEach((api) => api.setAuthHeader(authToken));
  }
}

export default new ApiManager();
