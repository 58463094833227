import store from '@/store';
import analytics from '@/utils/analytics';
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import EventInformationView from '@/views/EventInformationView.vue';
import LocationView from '@/views/LocationView.vue';
import FaqsView from '@/views/FaqsView.vue';
import RegistrationView from '@/views/RegistrationView.vue';
import CancellationView from '@/views/CancellationView.vue';
import EventCheckInView from '@/views/EventCheckInView.vue';
import URLGeneratorView from '@/views/URLGeneratorView.vue';
import EventReportingView from '@/views/EventReportingView.vue';
import PostTestCompletedView from '@/views/PostTestCompletedView.vue';

const initialize = ({ uic, name }) => {
  if (name === 'dealerPersonnel') {
    store.dispatch('initialize', { isDealer: true, uic });
  } else if (name === 'carenthusiasts') {
    store.dispatch('initialize', { isEnthusiast: true, uic });
  } else if (uic) {
    store.dispatch('initialize', { uic });
  } else store.dispatch('initialize');
};

const defaultMetaTitle = 'Acura Performance Drive';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: `Home | ${defaultMetaTitle}`,
    },
  },
  {
    path: '/dealer-personnel',
    name: 'dealerPersonnel',
    component: HomeView,
    meta: {
      title: `Home | ${defaultMetaTitle}`,
    },
  },
  {
    path: '/car-enthusiasts',
    name: 'carenthusiasts',
    component: HomeView,
    meta: {
      title: `Home | ${defaultMetaTitle}`,
    },
  },
  {
    path: '/event-information',
    name: 'eventInformation',
    component: EventInformationView,
    meta: {
      title: `Event Information | ${defaultMetaTitle}`,
    },
  },
  {
    path: '/location/:id',
    component: LocationView,
    props: ({ params }) => ({ id: parseInt(params.id, 10) }),
    meta: {
      title: `Location | ${defaultMetaTitle}`,
    },
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: FaqsView,
    meta: {
      title: `Faqs | ${defaultMetaTitle}`,
    },
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationView,
    meta: {
      title: `Registration | ${defaultMetaTitle}`,
    },
    props: ({ query }) => ({
      action: query.action,
      id: parseInt(query.id, 10),
      location: parseInt(query.location, 10),
    }),
  },
  {
    path: '/cancel-registration',
    name: 'cancelRegistration',
    component: CancellationView,
    meta: {
      title: `Cancel Registration | ${defaultMetaTitle}`,
    },
    props: ({ query }) => ({ id: query.id }),
    beforeEnter: (to) => {
      if (!to.query.id) return { path: '/' };
      return true;
    },
  },
  {
    path: '/event-check-in',
    name: 'eventCheckIn',
    component: EventCheckInView,
    meta: {
      title: `Event Check-in | ${defaultMetaTitle}`,
    },
  },
  {
    path: '/url-generator',
    name: 'urlGenerator',
    component: URLGeneratorView,
    meta: {
      title: `URL Generator | ${defaultMetaTitle}`,
    },
  },
  {
    path: '/event-reporting',
    name: 'eventReporting',
    component: EventReportingView,
    meta: {
      title: `Event Reporting | ${defaultMetaTitle}`,
    },
  },
  {
    path: '/post-test-completed',
    name: 'postTestCompleted',
    component: PostTestCompletedView,
    meta: {
      title: `Post Test Completed | ${defaultMetaTitle}`,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  },
});

router.beforeEach(async (to) => {
  const { name, path, query: { uic } } = to;
  analytics.trackPageView({ name: name || path, url: path });
  initialize({ name, uic });
  document.title = to.meta.title || defaultMetaTitle;
});

export default router;
