<template>
  <div class="order-2 lg:order-1 mb-10 lg:mb-0">
    <h2
      class="
        text-2xl
        sm:text-3xl
        lg:text-4xl
        font-AvenirHeavy
        my-10
      "
    >
      <span class="border-b-8 border-acura-primary">NE</span>ED HELP?
    </h2>
    <p
      class="
        text-lg
        sm:text-xl
        lg:text-2xl
      "
    >
      Check out our
      <router-link to="/faqs" target="_blank" class="underline font-AvenirBlack">FAQs</router-link>
      for answers to common questions you may have.
      If you can't find the answer your looking
      for please contact us.
    </p>
    <a href="mailto:info@acuraperformancedrive.com">
      <button
        class="
          w-full
          lg:w-auto
          py-3
          lg:px-8
          my-10
          bg-acura-primary
          font-AvenirHeavy
          text-xl
          text-white
          uppercase
        "
      >
        Contact US
      </button>
    </a>
  </div>
</template>
