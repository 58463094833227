import { baseCRUDAPI } from './base';

export default class Home extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'Report');
  }

  async get({ locationId, inviteeType, eventDate }) {
    return this.axiosObject.get(
      `${this.apiBaseName}/report`,
      {
        params: {
          locationId,
          inviteeType,
          eventDate,
        },
      },
    );
  }

  async getFilterAll() {
    return this.axiosObject.get(`${this.apiBaseName}/filter`);
  }
}
