<script setup>
import { defineEmits } from 'vue';
import HeroHeader from '@/components/global/HeroHeader.vue';
// import cancelDesktopImg from '@/assets/img/cancellation-desktop.jpg';
import CancelText from '@/components/cancelRegistration/CancelText.vue';
import HeroSlashes from '@/components/global/HeroSlashes.vue';

const emit = defineEmits(['cancel-registration']);

const cancelRegistration = () => emit('cancel-registration');
</script>
<template>
<div class="relative h-[60rem] hidden xl:block">
  <div class="relative h-full">
    <div
      class="
        w-[60%]
        h-full
        flex
        justify-start
        bg-cancellation-car-desktop
        bg-no-repeat
        [background-position:center]
        [background-size:cover]
      ">
    </div>
    <div
      class="
        top-0
        absolute
        w-full
        h-full
        bg-cancellation-desktop
        bg-no-repeat
        [background-position:center,bottom_0_right_0_]
        [background-size:cover,50% 30rem]
      "
    ></div>
    <hero-slashes
      class="absolute top-5 -left-5 lg:top-0 lg:left-[2.125rem] lg:h-[5.5rem]"
    />
    <hero-slashes
      class="
        absolute
        bottom-20
        -right-5
        h-9
        lg:bottom-16 lg:right-[-5rem] lg:h-auto
      "
    />
    <div
      class="
        uppercase
        absolute
        top-[8rem]
        left-[6rem]
        h-full
        flex flex-col
        justify-center
        max-w-max
        font-AcuraBespokeBold
        text-white
        tracking-wide
        pl-7
        lg:pl-8
        xl:pl-16
        2xl:pl-32
      ">
    </div>
  </div>
  <div class="flex absolute top-[10rem] w-full">
    <div class="w-[60%] text-white">
      <h1
        class="
          uppercase
          mt-52
          lg:mt-40
          text-[4rem] xl:text-[4.5rem] [@media(min-width:1430px)]:text-[6rem]
          leading-[6rem]
          pl-[10rem]
          pt-[12rem]
          font-AcuraBespokeBold
        ">
        <span
          class="
            tracking-widest
            text-[3rem] xl:text-[3.5rem] [@media(min-width:1430px)]:xl:text-[4rem]
            block
          "
          >Cancel</span
        >
        Registration
      </h1>
    </div>
    <cancel-text @cancel-registration="cancelRegistration" />
  </div>
</div>
<div class="xl:hidden">
  <hero-header class="mb-[4rem]"
    backgroundClass="bg-cancellation-car-mobile md:bg-cancellation-car-desktop">
    <template v-slot:title>
      <h1 class="mt-52 lg:mt-40">
        <span
          class="
            tracking-widest
            text-3xl
            lg:text-4xl
            xl:text-5xl
            2xl:text-6xl
            block
          "
          >Cancel</span
        >
        Registration
      </h1>
    </template>
  </hero-header>
  <cancel-text
    @cancel-registration="cancelRegistration"
    class="text-center mb-[10rem]"
  />
</div>
</template>
