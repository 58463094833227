const formatDate = (dates) => {
  if (dates !== null) {
    const day1 = new Date(dates);
    return `${day1.toLocaleString('en-US', { month: 'long' })} ${day1.getDate()}`;
  }
  return 'undefined';
};

const formatDate2 = (d) => {
  const date = new Date(d);
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${month}/${day}/${year}`;
};

const formatTime = (dateTime) => {
  const date = new Date(dateTime);
  const hours = date.getHours();
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const meridiem = hours >= 12 ? 'PM' : 'AM';
  return `${hours > 12 ? hours - 12 : hours}:${minutes} ${meridiem}`;
};

export {
  formatDate,
  formatDate2,
  formatTime,
};
