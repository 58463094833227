import { baseCRUDAPI } from './base';

export default class Print extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'Print');
  }

  async postPrintLabel(data) {
    return this.axiosObject.post(
      `${this.apiBaseName}/AddLabel`,
      data,
      {
        headers: {
          'Content-type': 'application/json',
        },
      },
    );
  }
}
