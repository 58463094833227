const locations = {
  // Chicago, IL
  1: {
    heroVideoFiles: [{
      src: require('@/assets/video/chicago.mp4'),
      type: 'video/mp4',
    }],
    heroVideoTrackSrc: '/vtt/chicago.vtt',
    mapSrc: `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_API_KEY}
    &q=Soldier+field,Chicago+Il`,
    linkName: 'Chicago',
  },
  // New York, NY
  2: {
    heroVideoFiles: [{
      src: require('@/assets/video/ny.mp4'),
      type: 'video/mp4',
    }],
    heroVideoTrackSrc: '/vtt/ny.vtt',
    mapSrc: `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_API_KEY}
    &q=Metlife+stadium,East+rutherford,New+Jersey`,
    linkName: 'New York',
  },
  // Miami, FL
  3: {
    heroVideoFiles: [{
      src: require('@/assets/video/miami.mp4'),
      type: 'video/mp4',
    }],
    heroVideoTrackSrc: '/vtt/miami.vtt',
    mapSrc: `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_API_KEY}
    &q=Hard+rock+stadium,+Miami+Fl`,
    linkName: 'Miami',
  },
  // Dallas, TX
  4: {
    heroVideoFiles: [{
      src: require('@/assets/video/dallas.mp4'),
      type: 'video/mp4',
    }],
    heroVideoTrackSrc: '/vtt/dallas.vtt',
    mapSrc: `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_API_KEY}
    &q=ATT+stadium,Arlington+Tx`,
    linkName: 'Dallas',
  },
  // Los Angeles, CA
  5: {
    heroVideoFiles: [{
      src: require('@/assets/video/huntington-beach.mp4'),
      type: 'video/mp4',
    }],
    heroVideoTrackSrc: '/vtt/huntington-beach.vtt',
    mapSrc: `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_API_KEY}
    &q=Angel+stadium,Anaheim+Ca`,
    linkName: 'Los Angeles',
  },
};

export default locations;
