<script setup>
import apiManager from '@/api';

const props = defineProps({
  uic: String,
  id: Number,
});

const putAddTime = async () => {
  const { data } = await apiManager.reservations.putAddTime(
    { uic: props.uic, sessionId: props.id },
  );
  return data;
};

const emit = defineEmits(['click', 'close-toast']);

function click(e) {
  emit('click', e);
  if (e === true) {
    putAddTime();
  }
}
</script>
<template>
  <div class="container">
    <h2 class="mb-5 text-center">
      Your session will expire in five minutes.
      <br>
      Do you want more time to register?
    </h2>
    <div class="flex justify-center gap-4">
      <button
      @click.stop="click(true), $emit('close-toast')"
        class="
        uppercase text-white border
        border-box border-white py-2
        px-8
      ">
        Yes
      </button>
      <button
      @click="$emit('close-toast')"
      class="
        uppercase text-white border
        border-box border-white py-2
        px-8
      ">
        No
      </button>
    </div>
  </div>
</template>
