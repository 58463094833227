<script setup>
import isNil from '@/utils/isNil';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import GenericLocation from '@/components/location/GenericLocation.vue';
import apiManager from '@/api';

const props = defineProps({
  id: Number,
});

const store = useStore();

const changeTitle = (title) => { document.title = title; };

const location = computed(() => {
  const location2 = store.getters['locations/location'](props.id);
  if (!isNil(location2?.locationId)) changeTitle(`${location2.city} | ${document.title}`);
  return location2;
});

const inviteeType = computed(() => store.state.user.inviteeType);

const sessions = ref([]);

const getSessions = async ({
  inviteeType: _inviteeType, locationId,
}) => {
  const { data } = await apiManager.reservations.getSessions({
    inviteeType: _inviteeType, locationId,
  });
  return data;
};

const loadSessions = async ({ inviteeType: _inviteeType, locationId }) => {
  const data = await getSessions({ inviteeType: _inviteeType, locationId });
  sessions.value = data;
};

if (!isNil(inviteeType.value)) {
  loadSessions({
    inviteeType: inviteeType.value,
    locationId: props.id,
  });
}

watch(inviteeType, () => {
  if (!isNil(inviteeType.value)) {
    loadSessions({
      inviteeType: inviteeType.value, locationId: props.id,
    });
  }
});

</script>
<template>
  <generic-location :location="location" :sessions="sessions"/>
</template>
