<template>
  <div class="home">
    <hero-section />
    <overview-section class="relative -mt-[9.25rem]" />
    <location-section />
  </div>
</template>

<script>
import HeroSection from '@/components/global/HeroSection.vue';
import OverviewSection from '@/components/overview/OverviewSection.vue';
import LocationSection from '@/components/locations/LocationSection.vue';

export default {
  name: 'HomeView',
  components: {
    HeroSection,
    OverviewSection,
    LocationSection,
  },
  props: {
    uic: String,
    isDealer: Boolean,
    isEnthusiast: Boolean,
  },
};
</script>
