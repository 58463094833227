<script setup>
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import FeaturedVehicle from '@/components/featuredVehicle/FeaturedVehicle.vue';
import IconSection from '@/components/eventInformation/IconSection.vue';
import HeroHeader from '@/components/global/HeroHeader.vue';
import LinkButton from '@/components/global/LinkButton.vue';
import { computed } from 'vue';
import iconSections from './iconSections';

const vehicles = [
  {
    title: 'Integra',
    url: 'https://www.acura.com/integra',
    bgClass: 'bg-integra-featured',
  },
  {
    title: 'MDX Type S',
    url: 'https://www.acura.com/type-s?deeplink=mdx_type_s',
    bgClass: 'bg-mdx-featured',
  },
  {
    title: 'TLX Type S',
    url: 'https://www.acura.com/type-s?deeplink=PERFORMANCE',
    bgClass: 'bg-tlx-featured',
  },
];

const props = defineProps({
  locations: Array,
  userType: Object,
});

const icons = computed(() => {
  if (props.userType.isConsumer) return iconSections.consumer;
  if (props.userType.isEnthusiast) return iconSections.enthusiast;
  if (props.userType.isVIP) return iconSections.vip;
  if (props.userType.isDealer) return iconSections.dealer;
  if (props.userType.isMedia) return iconSections.media;
  return [];
});
</script>

<template>
  <div
    class="
      bg-[url('assets/img/section-dot-pattern.svg')]
      bg-right-bottom
      bg-no-repeat
      bg-contain pb-16
      lg:pb-[10rem] lg:bg-auto
    "
  >
    <hero-header backgroundClass="bg-overview-hero-mobile lg:bg-overview-hero">
      <template v-slot:title>
        <h1 class="mt-52 lg:mt-40">
          <span
            class="
              tracking-widest
              text-3xl
              lg:text-4xl
              xl:text-5xl
              2xl:text-6xl
              block
            "
            >Event</span
          >
          Information
        </h1>
      </template>
    </hero-header>
    <div class="px-5">
      <p
        class="
          text-[2rem]
          lg:text-[4.0625rem]
          font-AcuraBespokeLight
          leading-none
          text-center
          max-w-[88.75rem]
          mx-auto
          mt-11
          mb-16
        "
      >
        {{
          userType.isDealer
            ? `A limited-time immersive training experience with the all-new Integra and
          Type S MDX &amp; TLX`
            : `A limited-time immersive drive experience with the all-new Integra and
          Type S MDX &amp; TLX`
        }}
      </p>

      <icon-section :icons="icons" />

      <p
        class="
          font-AvenirBook
          text-[1.75rem]
          lg:text-[2rem]
          text-center
          pb-12
          lg:pb-16
        "
      >
        We can’t wait to share our obsession with Precision Crafted Performance.
      </p>

      <p
        class="
          text-[1.25rem]
          lg:text-[1.5rem]
          font-AvenirHeavy
          text-center
          mb-8
        "
      >
        THERE IS LIMITED AVAILABILITY FOR {{locations.length > 1 ? 'EACH' : 'THIS'}} EVENT,
        SO CLAIM YOUR SPOT TODAY!
      </p>

      <nav
        aria-label="locations"
        class="
          max-w-[68rem]
          flex flex-col
          lg:flex-row lg:justify-between
          mb-10
          m-auto
        "
      >
        <template v-for="location in locations" :key="location.locationId">
          <link-button v-if="locations.length === 1"
            class="bg-acura-primary max-w-[15rem]"
            :to="`/location/${location.locationId}`"
            :aria-label="`Link to location page for ${location.city}`"
          >
            <div class="text-white">
              <span class="mr-4">{{ location.city }}</span>
              <span class="text-white"
                ><font-awesome-icon :icon="faChevronRight"
              /></span>
            </div>
          </link-button>
          <router-link v-else
            class="
              text-acura-primary text-[1.25rem]
              font-AvenirBlack
              h-12
              text-center
              flex flex-col
              justify-center
              m-auto
              hover:underline
              focus:underline
              uppercase
            "
            :to="`/location/${location.locationId}`"
            :aria-label="`Link to location page for ${location.city}`"
          >
            <div>
              <span class="mr-4">{{ location.city }}</span>
              <span class="text-black"
                ><font-awesome-icon :icon="faChevronRight"
              /></span>
            </div>
          </router-link>
          <div class="w-0 lg:border-r-2 last-of-type:border-r-0"></div>
        </template>
      </nav>

      <h2
        class="
          text-[2rem]
          lg:text-[3.5rem]
          font-AcuraBespokeBook
          text-center
          mb-[1.25rem]
          lg:mb-10
        "
      >
        Featured Vehicles
      </h2>

      <div
        class="
          max-w-[1714px]
          mx-auto
          flex flex-col
          lg:flex-row
          w-full
          gap-1
          drop-shadow-lg
          mb-16
          lg:mb-32
        "
      >
        <featured-vehicle
          v-for="vehicle in vehicles"
          v-bind="vehicle"
          :key="vehicle.title"
        />
      </div>

      <h2
        class="
          text-[2rem]
          lg:text-[3.5rem]
          font-AcuraBespokeBook
          text-center
          mb-[1.25rem]
          lg:mb-10
        "
      >
        Performance Drives
      </h2>

      <div class="max-w-[1710px] mx-auto">
        <div
          class="
            w-full
            flex flex-col
            justify-end
            md:flex-row md:justify-start
            text-white
            bg-gray-400
            h-[27rem]
            md:h-96
            pt-16
            mb-2
            md:pl-16
            text-left
            md:text-left
            drop-shadow-lg
            bg-integra-test-mobile bg-cover bg-no-repeat
            md:bg-integra-test-desktop
            md:[background-size:cover,cover]
            md:[background-position:center,right]
            p-5
          "
        >
          <div class="max-w-[33.5rem] mx-auto md:mx-0">
            <h3
              class="
                font-AcuraBespokeBook
                text-[1.5rem]
                md:text-4xl
                mb-4
                md:mb-10
              "
            >
              Integra Challenge
            </h3>

            <p
              class="
                text-[0.875rem]
                md:text-lg
                font-AvenirRoman
                leading-6
                md:leading-9 md:tracking-wider
              "
            >
              Experience the power and agility of the all-new 2023 Integra on a
              timed autocross course. Learn from our team of professional
              driving coaches—then push yourself and the Integra in the Integra
              Timed Challenge.
            </p>
          </div>
        </div>

        <div
          class="
            w-full
            flex flex-col
            justify-end
            md:flex-row md:justify-end
            text-white
            bg-gray-400
            h-[27rem]
            md:h-96
            pt-16
            md:pr-16
            text-left
            md:text-right
            drop-shadow-lg
            bg-tlx-test-mobile
            md:bg-tlx-test-desktop
            bg-cover
            p-5
          "
        >
          <div class="max-w-[33.5rem] mx-auto md:mx-0">
            <h3
              class="
                font-AcuraBespokeBook
                text-[1.5rem]
                md:text-4xl
                mb-4
                md:mb-10
              "
            >
              Type S Performance Drive – TLX and MDX
            </h3>

            <p
              class="
                text-[0.875rem]
                md:text-lg
                font-AvenirRoman
                leading-6
                md:leading-9 md:tracking-wider
              "
            >
              Acura Type S vehicles were born on the track, where Precision
              Crafted Performance is constantly improved through racing. Unlock
              your driving potential through professional driving
              instruction—then become one with the machine on our
              high-performance closed course.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
