<script setup>
import apiManager from '@/api';
import CancelRegistration from '@/components/cancelRegistration/CancelRegistration.vue';
import { useToast } from 'vue-toastification';

const props = defineProps({
  id: String,
});

const toast = useToast();

const cancel = async () => {
  try {
    await apiManager.reservations.cancel({ id: props.id });
    toast.success('Your registration was successfully cancelled');
  } catch (e) {
    toast.error('There was a problem cancelling your registration');
  }
};

</script>
<template>
<cancel-registration @cancel-registration="cancel" />
</template>
