<template>
  <nav
    class="
      h-[4.5rem]
      bg-black
      text-white
      font-AvenirMed
      text-2xl
      border-b-[3px] border-acura-primary
      lg:h-[6.25rem]
      lg:font-AvenirHeavy
      lg:text-xl
      lg:flex
      lg:justify-between
      lg:items-center
      lg:pr-10
      xl:pr-24
    "
  >
    <div
      class="
        h-full
        bg-black
        flex
        items-center
        justify-between
        relative
        z-50
        pl-4
        pr-2
        lg:pl-10
        xl:pl-24
      "
    >
      <router-link
        to="/"
        @click="
          closeNavDrawer();
          closeLocationsDropdown();
        "
      >
        <img
          alt="Acura logo"
          src="@/assets/img/acura-logo-vector.svg"
          class="invert w-[10.625rem] lg:w-[14.375rem]"
        />
      </router-link>
      <button
        @click="toggleNavDrawer"
        class="w-8 h-8 pt-1 text-2xl text-acura-primary lg:hidden"
      >
        <scale-in-out-center>
          <font-awesome-icon v-if="!navDrawerOpen" key="1" :icon="faBars" />
          <font-awesome-icon v-else key="2" :icon="faTimes" />
        </scale-in-out-center>
      </button>
    </div>
    <ul
      :class="navDrawerOpen ? 'top-[4.5rem]' : '-top-full'"
      class="
        absolute
        w-full
        bg-black
        text-white text-center
        uppercase
        tracking-[0.036em]
        transition-top
        duration-300
        ease-in-out
        z-40
        lg:relative
        lg:w-auto
        lg:bg-transparent
        lg:text-left
        lg:top-auto
        lg:flex
        lg:space-x-16
        lg:normal-case
        xl:space-x-24
      "
    >
      <li class="border-b border-acura-gray-dark lg:border-none">
        <router-link
          active-class="text-acura-primary"
          to="/event-information"
          @click="
            closeNavDrawer();
            closeLocationsDropdown();
          "
          class="block py-6 lg:py-0 hover:text-acura-gray-dark"
          >Event Information</router-link
        >
      </li>
      <li
        :class="locationsDropdownOpen ? 'max-h-[21.5rem]' : 'max-h-[4.75rem]'"
        class="lg:h-auto transition-maxHeight duration-300 ease-in-out"
      >
        <button
          @click="toggleLocationsDropdown"
          :class="{
            'text-acura-primary': $route.path.includes('/location'),
          }"
          class="
            hover:text-acura-gray-dark
            uppercase
            py-6
            lg:normal-case lg:py-0
          "
        >
          Location<span :class="{ hidden: locations.length === 1 }">s</span
          ><font-awesome-icon
            :icon="faChevronRight"
            :class="{ 'rotate-90': locationsDropdownOpen }"
            class="
              text-base text-acura-primary
              stroke-[3.75rem] stroke-acura-primary
              transition-transform
              duration-300
              ml-2
            "
          />
        </button>
        <transition
          enter-active-class="transition-opacity duration-300 ease-in"
          enter-from-class="opacity-0"
          leave-active-class="transition-opacity duration-200 ease-out"
          leave-to-class="opacity-0"
        >
          <div
            v-show="locationsDropdownOpen"
            class="
              bg-black
              flex flex-col
              space-y-6
              font-AvenirMed
              text-xl
              px-4
              pb-6
              lg:text-lg
              lg:px-12
              lg:pt-6
              lg:pb-7
              lg:absolute
              lg:top-0
              lg:left-0
              lg:translate-x-[11.25rem]
              lg:translate-y-[4.1rem]
              lg:border
              lg:border-[#E5E5E5]
              lg:border-t-0
              xl:translate-x-[13.5rem]
            "
          >
            <router-link
              v-for="location in locations"
              :key="location.locationId"
              active-class="text-acura-primary"
              :to="{ path: `/location/${location.locationId}` }"
              @click="
                closeLocationsDropdown();
                closeNavDrawer();
              "
              class="hover:text-acura-gray-dark"
              >{{ location.city }}</router-link
            >
          </div>
        </transition>
      </li>
      <li class="border-t border-acura-gray-dark lg:border-none">
        <router-link
          active-class="text-acura-primary"
          to="/faqs"
          @click="
            closeNavDrawer();
            closeLocationsDropdown();
          "
          class="block py-6 lg:py-0 hover:text-acura-gray-dark"
          >FAQs</router-link
        >
      </li>
    </ul>
  </nav>
</template>

<script>
import { faBars, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import ScaleInOutCenter from '@/components/transitions/ScaleInOutCenter.vue';

export default {
  name: 'PrimaryNavbar',
  components: {
    ScaleInOutCenter,
  },
  props: {
    locations: Array,
  },
  data() {
    return {
      faBars,
      faTimes,
      faChevronRight,
      navDrawerOpen: false,
      locationsDropdownOpen: false,
    };
  },
  methods: {
    toggleNavDrawer() {
      this.navDrawerOpen = !this.navDrawerOpen;
      this.toggleBodyClass();
    },
    closeNavDrawer() {
      this.navDrawerOpen = false;
      this.toggleBodyClass();
    },
    toggleLocationsDropdown() {
      this.locationsDropdownOpen = !this.locationsDropdownOpen;
    },
    closeLocationsDropdown() {
      this.locationsDropdownOpen = false;
    },
    toggleBodyClass() {
      const el = document.body;
      if (this.navDrawerOpen === true) {
        el.classList.add('overflow-hidden');
      } else {
        el.classList.remove('overflow-hidden');
      }
    },
  },
};
</script>
