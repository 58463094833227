import { createStore } from 'vuex';
import user from './modules/user';
import locations from './modules/locations';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    // Action for initializing the state on site/page load.
    // Checks for a stored uic, ignores the stored uic if a new uic is passed in url
    // Validates the selected UIC, and stores the validated uic in localStorage.
    // Then looks up locations if the inviteeType in the store has changed.
    async initialize({ dispatch, state }, { uic, isDealer, isEnthusiast } = {}) {
      const initialType = state.user.inviteeType;
      const { inviteeType } = await dispatch('user/initialUIC', { uic, isDealer, isEnthusiast });

      // only load locations if inviteeType has changed
      if (initialType !== inviteeType) dispatch('locations/loadLocations', { inviteeType });
    },
  },
  modules: {
    user,
    locations,
  },
});
