<script setup>

import ReportingOptions from './ReportingOptions.vue';
import ReportingTable from './ReportingTable.vue';

defineProps({
  tableData: {
    type: Object,
  },
  filterData: {
    type: Object,
  },
});

const emits = defineEmits(['getMetrics']);

// const emits = defineEmits(['getMetrics']);
const getMetrics = (x) => emits('getMetrics', x);

</script>
<template>
  <div>
    <reporting-options
      class="mb-[2rem]"
      :filterData="filterData"
      @submit="getMetrics"
    />
    <reporting-table v-if="tableData.data.length" v-bind="tableData" />
  </div>
</template>
