<script setup>
/* eslint-disable vuejs-accessibility/label-has-for */
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';
import { reactive, computed, watch } from 'vue';
import {
  getLocations,
  getInviteeTypes,
  getDates,
  filterByLocationId,
  filterByInviteeType,
} from './utils';

const props = defineProps({
  filterData: {
    type: Object,
  },
});
// form inputs
const initialInputs = {
  location: '',
  inviteeType: '',
  date: undefined,
};
const inputs = reactive({ ...initialInputs });

const locations = computed(() => (props.filterData ? getLocations(props.filterData) : []));

const inviteeTypes = computed(() => (
  props.filterData ? getInviteeTypes(
    inputs.location ? filterByLocationId(inputs.location)(props.filterData) : props.filterData,
  ) : []));

const dates = computed(() => {
  if (!props.filterData) return [];
  const options = props.filterData;
  const inviteeFiltered = inputs.inviteeType
    ? filterByInviteeType(inputs.inviteeType)(options) : options;
  const locationFiltered = inputs.location
    ? filterByLocationId(inputs.location)(inviteeFiltered) : inviteeFiltered;
  return getDates(locationFiltered);
});

watch(() => inputs.location, () => {
  inputs.inviteeType = initialInputs.inviteeType;
  inputs.date = initialInputs.date;
});
watch(() => inputs.inviteeType, () => {
  inputs.date = initialInputs.date;
});
// utils
// const capitalize = (str) => `${str[0].toUpperCase()}${str.slice(1)}`;

const clearOptions = () => {
  inputs.location = initialInputs.location;
  inputs.inviteeType = initialInputs.inviteeType;
  inputs.date = initialInputs.date;
};

// Emits
const emit = defineEmits(['submit']);

const getMetrics = () => emit('submit', inputs);

</script>
  <template>
    <div>
      <h1 class="text-xl">Event Reporting</h1>
      <form @submit.prevent="getMetrics">
        <fieldset
          class="
            border flex justify-between
            flex-col sm:flex-row
            w-[12rem] md:w-[32rem]
            p-[1rem] mb-[1rem]
          ">
          <legend>Filter Metrics</legend>
          <label for="event-location" class="mb-[0.5rem] md:mb-0">Event Location
            <select v-model="inputs.location" id="event-location" class="block">
              <option value="">All Locations</option>
              <option v-for="{label, value} in locations" :key="value"
                :value="value"
              > {{ label }}</option>
            </select>
          </label>
          <label for="invitee-type" class="mb-[0.5rem] md:mb-0">Invitee Type
            <select v-model="inputs.inviteeType" id="invitee-type" class="block">
              <option value="" >All Invitee Types</option>
              <option v-for="{ value, label } in inviteeTypes" :key="value"
                :value="value"
              > <span class="capitalize">{{ label }}</span></option>
            </select>
          </label>
          <label for="event-date" class="mb-[0.5rem] md:mb-0">Event Date
            <date-picker v-model="inputs.date"
              :available-dates="dates"
              id="event-date" type="date"
              :modelConfig="{
                type: 'string',
                mask: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  id="event-date"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </date-picker>
          </label>
        </fieldset>
        <button
            class="border px-[1rem] bg-acura-primary text-white
              h-[4rem] w-[10rem]
            "
            @click.prevent="clearOptions">CLEAR FILTERS</button>
        <button
          class="border px-[1rem] bg-acura-primary text-white ml-4
            h-[4rem]  w-[10rem]
          "
          type="submit">GET METRICS</button>
      </form>
    </div>
  </template>
  <style scoped>
    select option {
      text-transform: capitalize;
    }

    select, input {
      border: 1px solid black;
    }
  </style>
