<script setup>
import {
  computed, watch, reactive,
} from 'vue';
import useValidate from '@vuelidate/core';
import {
  helpers,
  required,
} from '@vuelidate/validators';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import apiManager from '@/api';
import TheFieldset from '@/components/global/TheFieldset.vue';
import TheButton from '@/components/global/TheButton.vue';
import TheSelect from '@/components/global/TheSelect.vue';
import TheRadio from '@/components/registration/TheRadio.vue';

const store = useStore();

/** CONTENT */
const state = reactive({
  locationsAvailable: [],
  inviteeTypes: [],
  locationSelected: '',
  inviteeTypeSelected: '',
  generatedURL: '',
});

/** VALIDATION */
const toast = useToast();

const locationSelectedValidator = helpers.withMessage('Please select Location', required);
const inviteeSelectedValidator = helpers.withMessage('Please select Invitee Type', required);
const rules = computed(() => (
  {
    locationSelected: {
      locationSelectedValidator,
    },
    inviteeTypeSelected: {
      inviteeSelectedValidator,
    },
  }
));

const v$ = useValidate(rules, state, { $autoDirty: true });

/** METHODS */
async function generateURL() {
  v$.value.$validate();

  if (v$.value.$error) {
    toast.error('Please fill the required fields');
  } else {
    try {
      const { data } = await apiManager.checkIn
        .getRegisterUrl(state.locationSelected, state.inviteeTypeSelected);
      if (data) {
        state.generatedURL = data;
      }

      v$.value.$reset();

      toast.success('URL Generated');
    } catch (err) {
      toast.error('Sorry, can\'t get the URL. Try later.');
    }
  }
}

/**
 * Get All Locations Available and set the dropdown
 */
state.locationsAvailable = computed(() => store.getters['locations/locationsAvailable']
  .map((e) => ({
    name: e.locationName,
    value: e.locationId,
  })));

/**
 * Set the locations available by Invitee Type
 */
state.inviteeTypes = computed(() => store.getters['locations/locationsInvitee']
  .map((e) => ({
    label: e.inviteeTypeName,
    value: e.inviteeType,
    locations: e.locationModel.map((x) => x.locationId),
  })));

const inviteeTypesFilter = computed(() => (
  state.locationSelected
    ? state.inviteeTypes.filter((x) => x.locations.includes(Number(state.locationSelected)))
    : state.inviteeTypes));

// Get All Locations and Invitee Types
store.dispatch('locations/loadAllLocations');

watch(() => state.locationSelected, () => {
  state.inviteeTypeSelected = '';
});
</script>

<template>
  <div class="p-5 lg:p-14 grid lg:grid-cols-6">
    <div class="col-span-6">
      <div class="grid sm:grid-cols-2">
        <h1 class="
          mb-10
          text-2xl
          sm:text-3xl
          lg:text-4xl
          font-AvenirHeavy
          uppercase
        ">
          <span class="border-b-8 border-acura-primary">URL</span> Generator
        </h1>
      </div>
      <form
          @submit.prevent="generateURL"
          class="col-span-10 lg:col-span-9"
        >
        <TheFieldset
          legend="Generate URL"
          class="
            grid gap-6
            grid-cols-1
            md:grid-cols-2
            lg:grid-cols-3
          "
        >
          <div class="generate-url__location">
            <span class="input-label mb-2 block">Location*:</span>
            <TheSelect
              name="Location"
              :options="state.locationsAvailable"
              :dirty="v$.locationSelected.$dirty"
              :errorMessage="v$.locationSelected.$errors[0]?.$message"
              v-model:value="state.locationSelected"
            />
          </div>
          <div class="generate-url__invitee">
            <span class="input-label mb-5 block">Invitee Type*:</span>
            <div class="flex flex-wrap" v-if="state.locationSelected">
              <the-radio
                name="invitee-type"
                @input="state.inviteeTypeSelected = $event"
                :options="inviteeTypesFilter"
                :dirty="v$.inviteeTypeSelected.$dirty"
                :errorMessage="v$.inviteeTypeSelected.$errors[0]?.$message"
              />
            </div>
          </div>
          <TheButton
            type="submit"
            class="
              max-w-xs
              md:col-start-2
              lg:col-start-3
              self-center
              border-2 border-acura-primary
            "
            label="GET URL"
            :disabled="!state.inviteeTypeSelected || !state.locationSelected"
          />
        </TheFieldset>
      </form>

      <div v-if="state.generatedURL" class="generate-url__link mt-4">
        <a class="text-blue-500" :href="state.generatedURL" target="_blank">
          {{ state.generatedURL }}
        </a>
      </div>
    </div>
  </div>
</template>
