<script setup>
import EventReporting from '@/components/reporting/EventReporting.vue';
import { reactive } from 'vue';
import apiManager from '@/api';
import { getUserTypeByID } from '@/utils/userType';
import { capitalize } from '@/utils/string';
import locationsContext from '@/store/modules/locations/locationsContext';

const formatTableData = (reportObj) => {
  const keys = Object.keys(reportObj);
  return keys.map((key) => [reportObj[key].name, reportObj[key].value]);
};

const loadReport = async ({ locationId, inviteeType, eventDate }) => {
  const { data } = await apiManager.report.get({ locationId, inviteeType, eventDate });
  return data;
};

const tableDataInitial = {
  location: undefined,
  userType: undefined,
  date: undefined,
  data: [],
};

const state = reactive({
  tableData: { ...tableDataInitial },
  filterData: undefined,
});

const resetTableData = () => {
  state.tableData.location = tableDataInitial.location;
  state.tableData.userType = tableDataInitial.userType;
  state.tableData.date = tableDataInitial.date;
  state.tableData.data = tableDataInitial.data;
};

const getMetrics = async ({ location, inviteeType, date }) => {
  resetTableData();
  const report = await loadReport({ locationId: location, inviteeType, eventDate: date });
  state.tableData.location = locationsContext[location]?.linkName || 'All Locations';
  state.tableData.userType = capitalize(getUserTypeByID(inviteeType)?.title) || 'All Invitee Types';
  state.tableData.date = date || 'All Dates';
  state.tableData.data = formatTableData(report);
};

const loadFilterOptions = async () => {
  const { data: { data } } = await apiManager.report.getFilterAll();
  state.filterData = data;
};

loadFilterOptions();

</script>
<template>
  <event-reporting
    @get-metrics="getMetrics"
    :tableData="state.tableData"
    :filterData="state.filterData"
    class="px-[1rem] md:px-[8rem] py-[4rem]"/>
</template>
