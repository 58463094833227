<script setup>
const props = defineProps({
  name: { type: String, required: true },
  options: { type: Object, default: () => {} },
  errorMessage: String,
});

const emit = defineEmits(['input']);

function onChange(e) {
  const radios = document.getElementsByName(`radio--${props.name}`);
  radios.forEach((item) => {
    if (item.value === e.target.value) {
      if (e.target.checked) {
        emit('input', e.target.value);
      } else {
        emit('input', '');
      }
    }
  });
}

function onlyOne(radio) {
  const radios = document.getElementsByName(`radio--${props.name}`);
  radios.forEach((item) => {
    if (item.value !== radio) {
      const uncheck = item;
      uncheck.checked = false;
    }
  });
}

</script>
<template>
  <label
    v-for="option in props.options" v-bind:key="option.value"
    :for="`radio--${props.name}--${option.value}`"
    class="
      block
      xl:inline
      relative
      mb-8
      xl:mb-0
      mr-8
      pl-[30px]
      cursor-pointer
      select-none
      text-lg
      text-acura-gray-darker
    "
  >
    <input
      type="checkbox"
      :id="`radio--${props.name}--${option.value}`"
      :name="`radio--${props.name}`"
      :value="`${option.value}`"
      @change="onChange($event)"
      @click="onlyOne(`${option.value}`)"
      class="
        absolute
        opacity-0
      "
    />
    <span
      class="
        absolute
        h-[24px]
        w-[24px]
        top-0
        left-0
        bg-white
        border-2
        border-acura-gray-darker
        after:content-['']
        after:absolute
        after:hidden
      "
      :class="{ 'border-red-500': props.errorMessage }"
    ></span>
    {{ option.label }}
  </label>
  <small
    v-if="props.errorMessage"
    class="basis-full text-acura-primary">
    {{ props.errorMessage }}
  </small>
</template>
<style scoped>
input:hover ~ span {
  background: #F3F3F3;
}

input:focus ~ span {
  border: 2px solid #000;
}

input:checked ~ span::after {
  display: block;
}

span::after {
  left: 6px;
  top: 1px;
  width: 8px;
  height: 14px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
