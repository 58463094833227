import { baseCRUDAPI } from './base';

export default class CheckIn extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'CheckIn');
  }

  async postLoginCheckIn({ key }) {
    return this.axiosObject.post(
      `${this.apiBaseName}/Authenticate`,
      null,
      {
        headers: {
          AuthKey: key,
        },
      },
    );
  }

  async postWalkupTurned({ timeStamp }) {
    return this.axiosObject.post(
      `${this.apiBaseName}/Walkup/TurnedAway`,
      null,
      {
        params: {
          timeStamp,
        },
      },
    );
  }

  async postSearchCheckIn({
    authToken,
    locationId,
    email,
    lastName,
  }) {
    return this.axiosObject.post(
      `${this.apiBaseName}/Search`,
      null,
      {
        headers: {
          authToken,
        },
        params: {
          locationId,
          email,
          lastName,
        },
      },
    );
  }

  async postCheckIn(data, token) {
    return this.axiosObject.post(`${this.apiBaseName}/CheckIn`, data, {
      headers: {
        authToken: token,
        'Content-type': 'application/json',
      },
    });
  }

  async getRegisterUrl(locationId, inviteeType) {
    return this.axiosObject
      .get(`${this.apiBaseName}/Walkup/RegisterUrl`, {
        params: { locationId, inviteeType },
      });
  }
}
