<script setup>
defineProps({
  dates: Array,
  locationName: String,
  additionalContent: String,
  address: String,
  mapSrc: String,
});

const DaysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getDayName = (index) => DaysOfWeek[index];
const getMonthName = (month) => Months[month];

const formatDate = (date) => {
  const dateT = new Date(`${date}T00:00:00`);
  const dayName = getDayName(dateT.getDay());
  const monthName = getMonthName(dateT.getMonth());
  const day = dateT.getDate();
  const year = dateT.getFullYear();

  return `${dayName}, ${monthName} ${day}, ${year}`;
};
</script>
<template>
  <div
    class="
      flex flex-col
      lg:flex-row
      justify-center
      w-full
      max-w-[23.75rem]
      lg:max-w-full lg:h-[21rem]
      mx-auto
    "
  >
    <div class="lg:flex lg:flex-col lg:justify-center w-full lg:mx-[5rem]">
      <div
        class="
          font-AvenirHeavy
          uppercase
          text-[1.375rem]
          lg:text-[2.25rem]
          after:border-b-4
          after:border-acura-primary
          after:block
          after:w-[2.25rem]
          mb-[1.5rem]
        "
      >
        <p v-for="date in dates" :key="date">{{ formatDate(date) }}</p>
      </div>
      <div
        class="text-[1.0625rem] lg:text-[1.125rem] font-AvenirRoman pb-2"
      >
        <p class="uppercase mb-[0.5rem] font-AvenirHeavy">{{ locationName }}</p>
        <p>{{ address }}</p>
      </div>
      <div v-html="additionalContent" />
      <p class="italic text-[.875rem] lg:text-[1.125rem] mt-4">
        *Acura reserves the right to change locations, venues, and dates.
      </p>
    </div>
    <iframe
      :title="`Google map of ${locationName}`"
      class="
        max-w-[100%]
        w-[24.5rem]
        lg:w-full
        h-[13rem]
        lg:h-[21rem]
        drop-shadow-2xl
      "
      style="border: 0"
      loading="lazy"
      allowfullscreen
      referrerpolicy="no-referrer-when-downgrade"
      :src="mapSrc"
    >
    </iframe>
  </div>
</template>
