<script setup>
import EventInformation from '@/components/eventInformation/EventInformation.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();

const locations = computed(() => store.state.locations.locations);

const isMedia = computed(() => store.getters['user/isMedia']);
const isVIP = computed(() => store.getters['user/isVIP']);
const isConsumer = computed(() => store.getters['user/isConsumer']);
const isEnthusiast = computed(() => store.getters['user/isEnthusiast']);
const isDealer = computed(() => store.getters['user/isDealer']);

const userType = computed(() => ({
  isMedia: isMedia.value,
  isVIP: isVIP.value,
  isConsumer: isConsumer.value,
  isEnthusiast: isEnthusiast.value,
  isDealer: isDealer.value,
}));
</script>
<template>
  <event-information :locations="locations" :userType="userType" />
</template>
