<script setup>
import isNil from '@/utils/isNil';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import apiManager from '@/api';
import RegistrationPage from '@/components/registration/RegistrationPage.vue';

const props = defineProps({
  action: String,
  id: Number,
  location: Number,
});

const store = useStore();
const location = computed(() => store.getters['locations/location'](props.location));

const inviteeType = computed(() => store.state.user.inviteeType);

const session = ref({});

const getSession = async ({
  inviteeType: _inviteeType, locationId,
}) => {
  const { data } = await apiManager.reservations.getSessions({
    inviteeType: _inviteeType, locationId,
  });
  return data;
};

const loadSession = async ({ inviteeType: _inviteeType, locationId }) => {
  const data = await getSession({ inviteeType: _inviteeType, locationId });
  session.value = data.find((item) => item.sessionId === props.id);
};

if (!isNil(inviteeType.value)) {
  loadSession({
    inviteeType: inviteeType.value,
    locationId: props.location,
  });
}

watch(inviteeType, () => {
  if (!isNil(inviteeType.value)) {
    loadSession({
      inviteeType: inviteeType.value, locationId: props.location,
    });
  }
});
</script>

<template>
  <registration-page
    :action="action"
    :location="location"
    :session="session"
    :inviteeType="inviteeType"
  />
</template>
