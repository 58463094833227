<script setup>
import isNil from '@/utils/isNil';
import { useStore } from 'vuex';
import apiManager from '@/api';
import {
  computed,
  watch,
  ref,
  onMounted,
  onUnmounted,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import AddTime from './AddTime.vue';

const store = useStore();

const getUic = computed(() => store.state.user.uic);

const router = useRouter();
// eslint-disable-line
const route = useRoute();

const sessionTime = ref(0);

const timer = ref(60);

const reserveRegistration = async ({
  uic: _getUic, sessionId,
}) => {
  const { data } = await apiManager.reservations.postReserveRegistration(
    { uic: _getUic, sessionId },
  );
  return data;
};

const loadReserveRegistration = async ({ uic: _getUic, sessionId }) => {
  const data = await reserveRegistration({ uic: _getUic, sessionId });
  const t = data.split(':');
  const time = (+t[0]) * 60 + (+t[1]);
  sessionTime.value = time;
  timer.value = sessionTime.value;
};

if (!isNil(getUic.value)) {
  loadReserveRegistration({
    uic: getUic.value,
    sessionId: route.query.id,
  });
}

watch(sessionTime, () => {
  if (!isNil(getUic.value)) {
    loadReserveRegistration({
      uic: getUic.value,
      sessionId: route.query.id,
    });
  }
});

const toast = useToast();

let interval;

function countTimer() {
  if (!interval) {
    interval = setInterval(() => {
      timer.value -= 1;
      if (timer.value === 299) {
        toast.error(
          {
            component: AddTime,
            props: {
              id: route.query.id,
              uic: getUic,
            },
            listeners: {
              click: () => updateTimer({
                uic: getUic.value,
                sessionId: route.query.id,
              }),
            },
          },
          {
            timeout: false,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            icon: false,
            closeButton: false,
          },
        );
      }
      if (timer.value === 0) {
        toast.error('Your registration has timed out. Please try registering again', {
          timeout: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        });
        router.push('/');
      }
    }, 1000);
  } else {
    interval = null;
  }
}

const updateTimer = async ({ uic: _getUic, sessionId }) => {
  const data = await reserveRegistration({ uic: _getUic, sessionId });
  const t = data.split(':');
  const time = (+t[0]) * 60 + (+t[1]);
  sessionTime.value = time + 1;
  timer.value = sessionTime.value;
};

const convertTime = computed(() => {
  const minutes = Math.floor(timer.value / 60);
  const seconds = timer.value - minutes * 60;
  const digitMinutes = String(minutes).length;
  const digitSeconds = String(seconds).length;
  const suffix = minutes === 0 ? 'SECS' : 'MINS';
  return `${digitMinutes === 1 ? '0' : ''}${minutes}:${digitSeconds === 1 ? '0' : ''}${seconds} ${suffix}`;
});

onMounted(() => {
  countTimer();
});

onUnmounted(() => {
  clearInterval(interval);
});
</script>
<template>
  <h2 class="text-2xl">Time remaining</h2>
  <p  class="text-2xl text-left lg:text-right text-acura-primary">{{ convertTime }}</p>
</template>
<script>
</script>
