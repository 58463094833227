<script setup>
import { computed } from 'vue';

const props = defineProps({
  startTime: String,
  endTime: String,
  available: Number,
  sessionId: Number,
  locationId: Number,
});

const formatTime = (dateTime) => {
  const date = new Date(dateTime);
  const hours = date.getHours();
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const meridiem = hours >= 12 ? 'PM' : 'AM';
  return `${hours > 12 ? hours - 12 : hours}:${minutes} ${meridiem}`;
};

const formatDate = (dateTime) => {
  const date = new Date(dateTime);
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${month}/${day}`;
};

const getSessionTime = (startTime) => {
  const formattedStart = formatTime(startTime);
  // const formattedEnd = formatTime(endTime);
  const formattedDate = formatDate(startTime);

  // if (startTime && endTime) return `${formattedStart} - ${formattedEnd} ${formattedDate}`;
  if (startTime) return `${formattedStart} ${formattedDate}`;
  return '';
};

const formattedTime = computed(() => formatTime(props.startTime));
const isAvailable = computed(() => props.available > 0);

</script>
<template>
  <div class="
    bg-[#f7f7f7]
    px-[.5rem] md:px-0
    pt-[1.1rem] md:pt-0
    border-b-2
    flex flex-col md:flex-row
    h-[9rem]
  ">
    <div class="h-full w-full flex flex-col justify-center md:ml-[4rem]">
      <div
        class="w-full flex justify-between mb-[1rem] md:mb-0"
        :class="{ 'text-[#8f8f8f]': !isAvailable}"
      >
        <div class="h-full flex flex-col justify-center">
          <p
            class="
              p-0
              pr-2
              font-AvenirHeavy
              text-[1.25rem] md:text-[1.5rem] lg:text-[2.625rem]
              min-w-[13.125rem] md:min-w-[15rem] lg:min-w-[27rem]
            "
          >
            {{ getSessionTime(props.startTime, props.endTime)}}
          </p>
        </div>
        <div class="h-full flex flex-col justify-center">
          <p
            class="
              p-0
              uppercase
              text-[1.0625rem] md:text-[1.25rem] lg:text-[2.25rem]
            "
          >
            {{ available }} Seats Remaining
          </p>
        </div>
      </div>
    </div>
    <div class="
      mb-[.75rem] md:mb-0
      md:mx-[4rem]
      text-white
      text-[1rem] md:text-[1.25rem]
      tracking-[0.035rem] lg:tracking-[0.045rem]
      font-AvenirHeavy
      min-w-[12.5rem]
      h-full
      flex
      flex-col
      justify-center
    ">
      <router-link v-if="isAvailable"
        class="
          bg-acura-primary
          w-full
          h-[2.75rem] md:h-[4rem]
          pt-[.7rem] md:pt-[1.2rem]
          mx-auto
          block
          text-center
        "
        :to="`/registration?action=register&id=${sessionId}&location=${locationId}`"
        :aria-label="`Go to Registration for the ${formattedTime} slot`">
        REGISTER
      </router-link>
      <router-link v-if="!isAvailable"
        class="
          bg-[#5e6162]
          w-full
          h-[2.75rem] md:h-[4rem]
          pt-[.7rem] md:pt-[1.2rem]
          mx-auto
          block
          text-center
        "
        :to="`/registration?action=waitlist&id=${sessionId}&location=${locationId}`"
        :aria-label="`Go to Registration for the waitlist for the ${formattedTime} slot`">
        JOIN WAITLIST
      </router-link>
    </div>
  </div>
</template>
