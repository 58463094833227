<script setup>
import apiManager from '@/api';
import { ref, reactive, computed } from 'vue';
import useValidate from '@vuelidate/core';
import {
  helpers,
  required,
} from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
import TheFieldset from '../global/TheFieldset.vue';
import TheTextfield from '../registration/TheTextfield.vue';
import TheButton from '../global/TheButton.vue';

const toast = useToast();

const state = reactive({
  key: '',
});

const requiredValidator = helpers.withMessage('Field is required', required);

const rules = computed(() => (
  {
    key: {
      requiredValidator,
    },
  }
));

const v$ = useValidate(rules, state, { $autoDirty: true });

const loading = ref(false);

const emit = defineEmits(['enableCheckIn']);

function submitForm() {
  v$.value.$validate();
  if (!v$.value.$error) {
    const login = {
      key: state.key,
    };

    const loginCheckIn = async () => {
      loading.value = true;
      try {
        const { data } = await apiManager.checkIn.postLoginCheckIn(login);
        if (data) {
          emit('enableCheckIn', { enable: true, token: data });
        } else {
          toast.error('Please provide a valid key');
          emit('enableCheckIn', false);
        }
        loading.value = false;
      } catch (err) {
        toast.error('Please provide a valid key');
        emit('enableCheckIn', false);
        loading.value = false;
      }
    };

    loginCheckIn();
  } else {
    toast.error('Please fill the required field');
  }
}
</script>
<template>
  <div class="p-5 lg:p-14 grid lg:grid-cols-4">
    <div class="col-span-3 xl:col-span-2">
      <h1 class="
        mb-10
        text-2xl
        sm:text-3xl
        lg:text-4xl
        font-AvenirHeavy
        uppercase
      ">
      <span class="border-b-8 border-acura-primary">Che</span>ck-in Page Login
      </h1>
      <form
        @submit.prevent="submitForm"
        class="">
        <TheFieldset
          legend="Login to event check-in page"
          class="lg:grid-cols-4 gap-4"
        >
          <TheTextfield
            type="text"
            name="key"
            placeholder="KEY"
            maxLength="12"
            :dirty="v$.key.$dirty"
            :errorMessage="v$.key.$errors[0]?.$message"
            v-model:value="state.key"
            class="lg:col-span-3"
          />
          <TheButton
            type="submit"
            class="border-2 border-acura-primary"
            label="Login"
            :loading="loading"
          />
        </TheFieldset>
      </form>
    </div>
  </div>
</template>
