<template>
<router-link :to="path" :aria-label="ariaText" class="group">
    <div
      :class="isOne ? 'h-[200px] md:h-[385px] lg:h-[675px]' : 'h-[200px] md:h-[385px]'"
      class="
        location-card
        w-full
        bg-black
        mb-[1px]
        relative"
        @mouseenter="mouseEnter()"
        @focus="mouseEnter()"
        @mouseleave="mouseLeave()"
        @blur="mouseLeave()"
      >
      <img :src="img" :alt="altText"
        :class="isOne ? 'h-[200px] md:h-[385px] lg:h-[675px]' : 'h-[200px] md:h-[385px]'"
        class="
          w-full
          object-cover
          absolute"
      />
      <div class="container-video relative">
        <video
          :class="isOne ? 'h-[200px] md:h-[385px] lg:h-[675px]' : 'h-[200px] md:h-[385px]'"
          class="
            w-full
            object-cover
            absolute"
          muted
          preload
          loop="true"
          no-controls
          ref="cardVideo"
        >
          <source :src="video" type="video/mp4" />
        </video>
      </div>
      <div class="
        flex
        justify-between
        items-stretch
        w-full
        h-full
        bg-gradient-to-t
        from-black
        relative"
      >
        <div
          :class="
            isOne ?
            'mb-4 sm:mb-8 lg:mb-14 ml-4 sm:ml-10 lg:ml-20' :
            'mb-4 ml-4 xl:mb-8 xl:ml-10'"
          class="
            infos
            self-end
          ">
            <h2
              :class="
                isOne ?
                'text-4xl lg:text-7xl' :
                'text-2xl lg:text-3xl font-bold tracking-widest mb-2'"
              class="
                font-AcuraBespokeRegular
                text-white
                uppercase"
            >
              {{ location }}
            </h2>
            <p
              :class="
                  isOne ?
                  'lg:text-4xl font-AvenirHeavy mt-3' :
                  'text-lg lg:text-1xl font-bold'"
              class="
                text-white
                uppercase"
            >
              {{ formatDate(date) }}
            <b class="text-acura-primary">|</b>
              {{ timeFormat(startTime) }} - {{ timeFormat(endTime) }}
            </p>
        </div>
        <router-link
          :class="
              isOne ?
              'mb-4 sm:mb-8 lg:mb-14 mr-4 sm:mr-10 lg:mr-20' :
              'mb-4 mr-4 xl:mb-8 xl:mr-10'"
          class="
              p-2
              sm:p-4
              self-end
              my-10
              mx-10
              bg-hover-card
              bg-position-100
              bg-size-200-100
              ease-in-out
              duration-150
              group-hover:bg-position-0-100
              "
              :to="path"
              :aria-label="ariaText
            ">
            <font-awesome-icon :icon="faArrowRight" size="lg" color="white"/>
        </router-link>
      </div>
    </div>
</router-link>
</template>
<script>
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

export default {
  name: 'LocationCard',
  props: {
    img: { type: String, required: true },
    path: { type: String, required: true },
    altText: { type: String, required: true },
    ariaText: { type: String, required: true },
    video: { type: String, required: true },
    location: { type: String, required: true },
    date: { type: Array, required: true },
    startTime: { type: String, required: true },
    endTime: { type: String, required: true },
    isOne: { type: Boolean, required: true },
  },
  data() {
    return {
      faArrowRight,
    };
  },
  methods: {
    mouseEnter() {
      this.$refs.cardVideo.play();
    },
    mouseLeave() {
      this.$refs.cardVideo.load();
    },
    timeFormat(time) {
      if (time) {
        const [hour, minutes] = time.split(':');
        const date = new Date(0);
        date.setHours(hour);
        date.setMinutes(minutes);
        return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) || 'undefined';
      }
      return 'undefined';
    },
    formatDate(dates) {
      if (dates !== null) {
        const day1 = new Date(dates[0]);

        if (dates.length > 1) {
          const day2 = new Date(dates[dates.length - 1]);
          return `${day1.toLocaleString('en-US', { month: 'long' })} ${day1.getDate() + 1} - ${day2.getDate() + 1}`;
        }
        return `${day1.toLocaleString('en-US', { month: 'long' })} ${day1.getDate() + 1}`;
      }
      return 'undefined';
    },
  },
};
</script>
