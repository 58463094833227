<template>
  <div class="icon-card">
    <div class="icon-container  bg-acura-primary">
      <div class="icon">
        <slot name="icon">
          X
        </slot>
      </div>
    </div>
    <div class="text-[1.125rem] text-center leading-[2rem] lg:text-[1.25rem] font-AvenirRoman ">
      <slot name="text">
        Lorem Ipsum And Stuff
      </slot>
    </div>
  </div>
</template>
<style lang="css" scoped>
.icon-container {
  width: 112px;
  height: 112px;
  border-radius:56px;
  margin: 0 auto;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon {
  margin: 0 auto;
  color: white;
}

.text {
  letter-spacing: 0.72px;
}
</style>
