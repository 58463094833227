import { createApp } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import PrimaryNavbar from '@/components/global/PrimaryNavbar.vue';
import TheFooter from '@/components/global/TheFooter.vue';
import Toast from 'vue-toastification';
import { createMetaManager } from 'vue-meta';
import 'vue-toastification/dist/index.css';
import App from './App.vue';
import router from './router';
import store from './store';
import './main.css';

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('PrimaryNavbar', PrimaryNavbar)
  .component('TheFooter', TheFooter)
  .use(store)
  .use(router)
  .use(Toast, {
    toastClassName: 'acura',
    bodyClassName: ['acura'],
  })
  .use(createMetaManager(false, {
    robots: {
      tag: 'meta',
    },
  }))
  .mount('#app');
