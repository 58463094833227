<script setup>
import apiManager from '@/api';
import { ref, reactive, computed } from 'vue';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import useValidate from '@vuelidate/core';
import {
  email,
  helpers,
  required,
  requiredIf,
} from '@vuelidate/validators';
import { useMeta } from 'vue-meta';
import { formatTime, formatDate2 } from '@/utils/format';
import TheFieldset from '../global/TheFieldset.vue';
import TheButton from '../global/TheButton.vue';
import TheTextfield from '../registration/TheTextfield.vue';
import TheSelect from '../global/TheSelect.vue';

const props = defineProps({
  token: String,
});

useMeta({
  meta: [
    { name: 'robots', content: 'noindex' },
  ],
});

const locations = [
  {
    name: 'Chicago',
    value: '1',
  },
  {
    name: 'Los Angeles',
    value: '5',
  },
  {
    name: 'Miami',
    value: '3',
  },
];

const isSearching = ref(false);
const wasSearched = ref(false);
const isChecking = ref(false);
const isAdding = ref(false);
const isPrinting = ref(false);

const state = reactive({
  location: '',
  email: '',
  searchLastName: '',
  guestFirstName: '',
  guestLastName: '',
  attendeeUIC: '',
  columns: [
    {
      title: 'First Name',
      name: 'firstName',
    },
    {
      title: 'Last Name',
      name: 'lastName',
    },
    {
      title: 'Session Date',
      name: 'sessionDate',
    },
    {
      title: 'Start Time',
      name: 'startTime',
    },
    {
      title: 'Car #',
      name: 'carNumber',
    },
    {
      title: 'UIC',
      name: 'uic',
    },
    {
      title: 'Attendee Type',
      name: 'attendeeType',
    },
    {
      title: 'Walk-in?',
      name: 'walkIn',
    },
    {
      title: 'Child Count',
      name: 'childCount',
    },
    {
      title: 'Check-in',
      name: 'checkedIn',
    },
    {
      title: 'Print Label',
      name: 'printLabel',
    },
  ],
  rows: [],
});

const requiredValidator = helpers.withMessage('Field is required', required);
const emailValidator = helpers.withMessage('Please provide a valid email', email);

const rulesSearch = computed(() => (
  {
    location: {
      requiredValidator,
    },
    email: {
      requiredEmail: helpers.withMessage('Field is required', requiredIf(!state.searchLastName)),
      emailValidator,
    },
    searchLastName: {
      requiredEmail: helpers.withMessage('Field is required', requiredIf(!state.email)),
    },
  }
));

const rulesGuest = computed(() => (
  {
    attendeeUIC: {
      requiredValidator,
    },
    guestFirstName: {
      requiredValidator,
    },
    guestLastName: {
      requiredValidator,
    },
  }
));

const vSearch$ = useValidate(rulesSearch, state, { $autoDirty: true });
const vGuest$ = useValidate(rulesGuest, state, { $autoDirty: true });

function walkupTurnedAway() {
  if (window.confirm('I confirm that I want to record a walkup that was turned away.')) {
    const now = new Date();
    const date = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString().replace('T', ' ').substring(0, 23);

    const walkupTurned = () => {
      apiManager.checkIn.postWalkupTurned({ timeStamp: date });
    };
    walkupTurned();
  }
}

function search() {
  vSearch$.value.$validate();
  if (!vSearch$.value.$error) {
    const searchRequest = {
      authToken: props.token,
      locationId: state.location,
      email: state.email,
      lastName: state.searchLastName,
    };

    const searchCheckIn = async () => {
      isSearching.value = true;
      try {
        const { data } = await apiManager.checkIn.postSearchCheckIn(searchRequest);
        if (!data) {
          isSearching.value = false;
        }

        wasSearched.value = true;
        isSearching.value = false;
        state.rows = data;
      } catch (err) {
        isSearching.value = false;
      }
    };
    searchCheckIn();
  }
}

function searchClear() {
  state.location = '';
  state.email = '';
  state.searchLastName = '';
  state.rows = [];
  state.guestFirstName = '';
  state.guestLastName = '';
  state.attendeeUIC = '';
  vSearch$.value.$reset();
  vGuest$.value.$reset();
  wasSearched.value = false;
}

function checkIn(row) {
  const postCheckIn = async () => {
    isChecking.value = true;
    try {
      const { data } = await apiManager.checkIn.postCheckIn(row, props.token);
      if (!data) {
        isChecking.value = false;
      }
      isChecking.value = false;
    } catch (err) {
      isChecking.value = false;
    }
  };
  postCheckIn();
}

function guest() {
  vGuest$.value.$validate();
  if (!vGuest$.value.$error) {
    const guestRequest = {
      authToken: props.token,
      uic: state.attendeeUIC,
      guestFirstName: state.guestFirstName,
      guestLastName: state.guestLastName,
    };

    const addGuest = async () => {
      isAdding.value = true;
      try {
        const { data } = await apiManager.reservations.postAddGuest(guestRequest);
        if (!data) {
          isAdding.value = false;
        }
        isAdding.value = false;
        search();
      } catch (err) {
        isAdding.value = false;
      }
    };
    addGuest();
  }
}

function printLabel(row) {
  const payload = {
    firstName: row.firstName,
    lastName: row.lastName,
    carNumber: row.carNumber,
  };
  const postPrintLabel = async () => {
    isPrinting.value = true;
    try {
      const { data } = await apiManager.print.postPrintLabel(payload);
      if (!data) {
        isPrinting.value = false;
      }
      isPrinting.value = false;
    } catch (err) {
      isPrinting.value = false;
    }
  };
  postPrintLabel();
}
</script>
<template>
  <metainfo />
  <div class="p-5 lg:p-14 grid lg:grid-cols-6">
    <div class="col-span-6">
      <div class="grid sm:grid-cols-2">
        <h1 class="
          mb-10
          text-2xl
          sm:text-3xl
          lg:text-4xl
          font-AvenirHeavy
          uppercase
        ">
          <span class="border-b-8 border-acura-primary">Eve</span>nt Check-in
        </h1>
        <TheButton
          @click="walkupTurnedAway()"
          label="Walkup turned away"
          class="sm:px-8 mb-4 lg:col-start-5 lg:border-2 lg:border-acura-primary"
        />
      </div>
          <TheFieldset
            legend="Check-in Attendee"
            class="lg:grid-cols-10"
          >
          <form
            @submit.prevent="search"
            class="col-span-10 lg:col-span-9"
          >
            <TheFieldset
              legend="Search"
              class="
                md:grid-cols-3
                xl:grid-cols-6
                gap-4
                mb-4
              ">
              <TheSelect
                name="locations"
                defaultOption="Event Location*"
                :options="locations"
                :dirty="vSearch$.location.$dirty"
                :errorMessage="vSearch$.location.$errors[0]?.$message"
                v-model:value="state.location"
              />
              <TheTextfield
                type="email"
                name="Email"
                placeholder="Email"
                tooltip="Please provide the email you registered with."
                :dirty="vSearch$.email.$dirty"
                :errorMessage="vSearch$.email.$errors[0]?.$message"
                v-model:value="state.email"
              />
              <TheTextfield
                name="lastname"
                placeholder="Last Name"
                :dirty="vSearch$.searchLastName.$dirty"
                :errorMessage="vSearch$.searchLastName.$errors[0]?.$message"
                v-model:value="state.searchLastName"
              />
              <TheButton
                type="submit"
                class="
                  md:col-start-2
                  xl:col-start-5
                  border-2 border-acura-primary
                "
                label="SEARCH"
                :loading="isSearching"
              />
              <TheButton
                @click="searchClear()"
                class="border-2 border-acura-primary"
                label="CLEAR SEARCH"
              />
            </TheFieldset>
          </form>
          <span
            v-if="wasSearched && !state.rows?.length && !isSearching"
            class="border col-span-10 p-2">
            <p>No results found</p>
          </span>
          <span
            v-if="isSearching"
            class="border col-span-10 p-2">
            <font-awesome-icon
              :icon="faSpinner"
              color="#B5B5B5"
              size="xl"
              class="animate-spin relative left-[50%]"
            />
          </span>
          <table
            v-if="state.rows?.length && !isSearching"
            class="w-full table-fixed col-span-10 xl:border mb-4">
            <thead class="
              hidden
              xl:table-header-group
              overflow-hidden
              xl:overflow-visible
            ">
              <tr class="bg-acura-gray-medium">
                <th
                  v-for="(column, index) in state.columns" :key="index"
                  scope="col"
                  class="p-2
                ">
                  {{ column.title }}
                </th>
              </tr>
            </thead>
            <tbody class="text-center py-2">
              <tr
                v-if="isChecking || isPrinting"
                class="relative w-full left-[50%]">
                <font-awesome-icon
                  :icon="faSpinner"
                  color="#B5B5B5"
                  size="2xl"
                  class="p-4 animate-spin"
                />
              </tr>
              <tr
                v-else
                v-for="(row, index) in state.rows" :key="index"
                class="xl:odd:bg-acura-gray-light block xl:table-row border border-b-2 mb-4"
              >
                <td
                  v-for="(column, indexColumn) in state.columns" :key="indexColumn"
                  :data-label="column.title"
                  class="
                    py-2
                    xl:py-4
                    px-4
                    xl:px-0
                    block
                    xl:table-cell
                    text-right
                    xl:text-center
                    border-b
                    xl:border-none
                    before:content-[attr(data-label)]
                    xl:before:content-none
                    before:float-left
                    before:font-bold
                    odd:bg-acura-gray-light
                    xl:odd:bg-transparent
                  ">
                  <span v-if="column.name === 'walkIn'">
                    <label :for='`checkbox-walkIn-${row.uic}`'>
                      <input
                        :id="`checkbox-walkIn-${row.uic}`"
                        type="checkbox"
                        v-model="row[column.name]
                      ">
                    </label>
                  </span>
                  <span v-if="column.name === 'childCount'"
                  class="flex justify-end xl:justify-center">
                    <TheTextfield
                      class="w-16 "
                      type="number"
                      name="child-count"
                      min="0"
                      max="99"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      v-model:value="row[column.name]"
                    />
                  </span>
                  <span v-if="column.name === 'checkedIn'">
                    <TheButton
                      @click="checkIn(row)"
                      :label="column.title"
                      class="px-2"
                    />
                  </span>
                  <span v-if="column.name === 'printLabel'">
                    <TheButton
                      @click="printLabel(row)"
                      label="Print"
                      class="px-2"
                    />
                  </span>
                  <span
                    v-if="
                      column.name !== 'walkIn'
                      && column.name !== 'checkedIn'
                      && column.name !== 'childCount'
                    ">
                    {{
                      column.name === 'startTime' ? formatTime(row[column.name])
                      : column.name === 'sessionDate' ? formatDate2(row['startTime'])
                      : row[column.name]
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <form
            v-if="state.rows?.length"
            @submit.prevent="guest"
            class="col-span-10 xl:col-span-7"
          >
            <TheFieldset
              legend="Update/Add Guest"
              class="lg:grid-cols-5 gap-4 mb-4"
            >
              <TheTextfield
                  name="attendeeUIC"
                  placeholder="Attendee UIC"
                  :dirty="vGuest$.attendeeUIC.$dirty"
                  :errorMessage="vGuest$.attendeeUIC.$errors[0]?.$message"
                  v-model:value="state.attendeeUIC"
              />
              <TheTextfield
                  name="First Name"
                  placeholder="First Name"
                  :dirty="vGuest$.guestFirstName.$dirty"
                  :errorMessage="vGuest$.guestFirstName.$errors[0]?.$message"
                  v-model:value="state.guestFirstName"
              />
              <TheTextfield
                  name="lastname"
                  placeholder="Last Name"
                  :dirty="vGuest$.guestLastName.$dirty"
                  :errorMessage="vGuest$.guestLastName.$errors[0]?.$message"
                  v-model:value="state.guestLastName"
              />

              <TheButton
                type="submit"
                class="lg:col-start-5 lg:border-2 lg:border-acura-primary"
                label="Submit"
                :loading="isAdding"
              />
            </TheFieldset>
        </form>
        <div
          v-if="state.rows?.length"
          class="col-span-10 xl:col-span-3 text-center xl:col-start-8">
          <h2 class="font-bold">Attendee Type Legend</h2>
          <p>RA = Actively registered attendee.</p>
          <p>RG = Actively registered guest.</p>
          <p>WLA = Attendee that is still on the waiting list.</p>
          <p>WLG = Guest that is still on the waiting list.</p>
          <p>CIA = Attendee that has checked-in to the event</p>
          <p>CIG = Guest that has checked-in to the event</p>
        </div>
        <router-link
          to="/url-generator"
          target="_blank"
          class="underline col-span-10 xl:col-span-3 mt-2"
        >
          URL Generator
        </router-link>
        </TheFieldset>
    </div>
  </div>
</template>
