import { baseCRUDAPI } from './base';

export default class Home extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'Home');
  }

  async uic(uic) {
    return this.axiosObject.get(
      `${this.apiBaseName}/experience`,
      {
        params: {
          uic,
        },
      },
    );
  }

  async uicDealer(uic) {
    return this.axiosObject.get(
      `${this.apiBaseName}/experience`,
      {
        params: {
          isDealerPersonnel: true,
          uic,
        },
      },
    );
  }

  async uicEnthusiast(uic) {
    return this.axiosObject.get(
      `${this.apiBaseName}/experience`,
      {
        params: {
          isEnthusiast: true,
          uic,
        },
      },
    );
  }

  async faqs({ uic }) {
    return this.axiosObject.get(
      `${this.apiBaseName}/faqs`,
      {
        params: {
          uic,
        },
      },
    );
  }
}
