<script setup>
import { computed } from 'vue';

const props = defineProps({
  date: String,
  userType: String,
  location: String,
  data: Array,
});

// createCSVURI :: [[cellData]] -> String
const createCSVURI = (rows = []) => `data:text/csv;charset=utf-8,Metric,Count\n${rows.map((row) => row.join(',')).join('\n')}`;

const formatCSV = createCSVURI;

const csvURI = computed(() => (props.data ? formatCSV(props.data) : undefined));

</script>
<template>
  <table class="text-left mb-[2rem] w-[20rem]">
    <caption class="text-[1.2rem] mb-[1rem]">
      {{ `Results for ${date} | ${ userType } | ${location}`}}
    </caption>
    <thead>
      <tr>
        <th scope="col">Metric</th>
        <th scope="col">Count</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="row in data" :key="row[0]">
        <th scope="row">{{ row[0] }}</th>
        <td v-for="(datum, index) in row.slice(1)" :key="index">{{ datum }}</td>
      </tr>
    </tbody>
  </table>
  <a v-if="csvURI"
    :href="csvURI"
    :download="`Performance_drive_report_${location}_${userType}_${date}`"
    class="
      border
      px-[1rem]
      bg-acura-primary
      text-white
      pt-[1.2rem]
      h-[4rem] block w-[14rem] text-center uppercase
    "
  >Download Report</a>
</template>
<style scoped>
  table, th, td {
    border: 1px solid black;
  }
  thead {
    background: lightgrey;
  }
  th, td {
    padding: .8rem;
  }
  tr:nth-child(even){background-color: #f2f2f2;}
</style>
