const types = {
  DEALER: {
    id: 0,
    title: 'dealer',
    displayTitle: 'Dealer Personnel',
    codes: ['DP'],
  },
  // MEDIA: {
  //   id: 1,
  //   title: 'media',
  //   displayTitle: 'Media',
  //   codes: ['MI'],
  // },
  VIP: {
    id: 2,
    title: 'vip',
    displayTitle: 'VIP',
    codes: ['VI', 'DI'],
  },
  CONSUMER: {
    id: 3,
    title: 'consumer',
    displayTitle: 'Consumer',
    codes: ['CI', 'CW'],
  },
  ENTHUSIAST: {
    id: 4,
    title: 'enthusiast',
    displayTitle: 'Car Enthusiasts',
    codes: ['EI'],
  },
};

export default types;
