<script setup>
import { ref } from 'vue';
import LoginPage from '@/components/eventCheckIn/LoginPage.vue';
import CheckIn from '@/components/eventCheckIn/CheckIn.vue';

const showCheckIn = ref(false);
const token = ref('');

function enableCheckIn(e) {
  showCheckIn.value = e.enable;
  token.value = e.token;
}
</script>
<template>
  <login-page @enableCheckIn="enableCheckIn($event)" v-if="!showCheckIn"/>
  <check-in v-if="showCheckIn" :token="token"/>
</template>
