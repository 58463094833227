import UserTypes from '@/types/userTypes';
import { getUserTypeCode } from '@/utils/uic';

const getTitle = (userType) => userType?.title || '';

const isUserTypeByCode = (type) => (userCode) => type?.codes.includes(userCode) || false;

const isUserTypeByUIC = (userType) => (uic) => isUserTypeByCode(userType)(
  getUserTypeCode(uic?.toUpperCase()),
);

const isUserTypeByID = (userType) => (id) => userType?.id === id;

const typesToArray = (userTypes) => Object.values(userTypes);

const getUserTypeByUIC = (uic) => typesToArray(UserTypes)
  .find((userType) => isUserTypeByUIC(userType)(uic));

const getUserTypeByID = (id) => typesToArray(UserTypes)
  .find((userType) => isUserTypeByID(userType)(id));

const getTitleByUIC = (uic) => getTitle(getUserTypeByUIC(uic));

const isTypeByID = (userType) => (id) => userType.id === id;

const isDealer = isTypeByID(UserTypes.DEALER);

const isVIP = isTypeByID(UserTypes.VIP);

const isConsumer = isTypeByID(UserTypes.CONSUMER);

const isEnthusiast = isTypeByID(UserTypes.ENTHUSIAST);

export {
  getTitle,
  getTitleByUIC,
  getUserTypeByUIC,
  getUserTypeByID,
  isUserTypeByUIC,
  isTypeByID,
  isDealer,
  isVIP,
  isConsumer,
  isEnthusiast,
};
