const timeIcon = require('@/assets/img/clock-icon.svg');
const performanceIcon = require('@/assets/img/speedometer-icon.svg');
const racingIcon = require('@/assets/img/flags-icon.svg');
const utensilIcon = require('@/assets/img/utensil-icon.svg');
const theaterIcon = require('@/assets/img/theater-icon.svg');
const badgeIcon = require('@/assets/img/badge-icon.svg');

const icons = {
  timeIcon: {
    src: timeIcon,
    alt: 'An outline of a clock',
  },
  performanceIcon: {
    src: performanceIcon,
    alt: 'An outline of a speedometer',
  },
  racingIcon: {
    src: racingIcon,
    alt: 'An outline of two finish flags crossing to form an X',
  },
  utensilIcon: {
    src: utensilIcon,
    alt: 'An outline of basic eating utensils',
  },
  theaterIcon: {
    src: theaterIcon,
    alt: 'An outline of a home theater',
  },
  badgeIcon: {
    src: badgeIcon,
    alt: 'An outline of a generic medal with a star',
  },
};

const consumer = [
  {
    ...icons.timeIcon,
    text: 'Explore the history of Acura racing and the meticulous design of the Type S',
  },
  {
    ...icons.performanceIcon,
    text: 'Master performance driving skills under the instruction of professional driving coaches',
  },
  {
    ...icons.racingIcon,
    text: 'Savor the thrill of the Type S and Integra on two closed courses',
  },
];

const enthusiast = consumer;

const vip = [
  {
    ...icons.badgeIcon,
    text: 'Enjoy an exclusive event with other Acura enthusiasts and VIP guests',
  },
  {
    ...icons.performanceIcon,
    text: 'Master performance driving skills under the instruction of professional driving coaches',
  },
  {
    ...icons.racingIcon,
    text: 'Savor the thrill of the Type S and Integra on two closed courses',
  },
];

const dealer = [
  {
    ...icons.performanceIcon,
    text: 'Master performance driving skills under the instruction of professional driving coaches',
  },
  {
    ...icons.racingIcon,
    text: 'Savor the thrill of the Type S and Integra on two unique performance courses',
  },
  {
    ...icons.timeIcon,
    text: 'Explore the history of Acura racing and the feature stories of the Integra and Type S TLX and MDX models',
  },
];

const media = [
  {
    ...icons.theaterIcon,
    text: 'A special preview for Media before the Saturday consumer event',
  },
  {
    ...icons.timeIcon,
    text: 'Explore the history of Acura racing and the meticulous design of the Type S',
  },
  {
    ...icons.racingIcon,
    text: 'Savor the thrill of the Type S and Integra on two unique performance courses',
  },
];

export default {
  consumer,
  enthusiast,
  vip,
  dealer,
  media,
};
