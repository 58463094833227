<script setup>
import { useStore } from 'vuex';
import apiManager from '@/api';
import { reactive, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import useValidate from '@vuelidate/core';
import { formatDate, formatTime } from '@/utils/format';
import actions from '@/types/registrationActions';
import { faChevronRight, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import {
  helpers,
  required,
  email,
  sameAs,
  requiredIf,
} from '@vuelidate/validators';
import { isDealer } from '@/utils/userType';
import { useToast } from 'vue-toastification';
import RegistrationTimer from './RegistrationTimer.vue';
import TheTextfield from './TheTextfield.vue';
import TheCheckbox from './TheCheckbox.vue';
import TheRadio from './TheRadio.vue';

const props = defineProps({
  action: String,
  city: String,
  locationId: String,
  session: Object,
  sessionTime: Number,
  inviteeType: String,
});

const store = useStore();

const getUic = computed(() => store.state.user.uic);

const toast = useToast();

const router = useRouter();

const YesNo = { YES: 'yes', NO: 'no' };

const state = reactive({
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
  phoneNumber: '',
  zipCode: '',
  dptsid: '',
  signMe: false,
  yesOrNo: '',
  modelYear: '',
  acuraBrand: '',
  guestFirstName: '',
  guestLastName: '',
});

const requiredValidator = helpers.withMessage('Field is required', required);
const emailValidator = helpers.withMessage('Please provide a valid email', email);
const regexPhone = helpers.regex(/^(1[- .]?)?(\([2-9]\d{2}\)[. -]?|[2-9]\d{2}[. -]?)[2-9]\d{2}[- .]?\d{4}$/);
const phoneValidator = helpers.withMessage('Please provide a valid phone number', regexPhone);
const regexZipCode = helpers.regex(/^\d{5}(?:[-]\d{4})?$/);
const zipCodeValidator = helpers.withMessage('Please provide a valid zip code', regexZipCode);
const regexModelYear = helpers.regex(/^\d{4}\s\w+$/);

const rules = computed(() => (
  {
    firstName: { requiredValidator },
    lastName: { requiredValidator },
    email: {
      requiredValidator,
      emailValidator,
    },
    confirmEmail: {
      requiredValidator,
      emailValidator,
      sameAs: helpers.withMessage('Provided emails do not match', sameAs(state.email)),
    },
    phoneNumber: {
      requiredValidator,
      phoneValidator,
    },
    zipCode: {
      requiredValidator,
      zipCodeValidator,
    },
    dptsid: {
      requiredMedia: helpers.withMessage('Field is required', requiredIf(isDealer(props.inviteeType))),
    },
    guestFirstName: {
      requiredGln: helpers.withMessage('Field is required', requiredIf(state.guestLastName)),
    },
    guestLastName: {
      requiredGfn: helpers.withMessage('Field is required', requiredIf(state.guestFirstName)),
    },
    modelYear: {
      modelYear: helpers.withMessage('YYYY MODEL', regexModelYear),
    },
  }
));

const v$ = useValidate(rules, state, { $autoDirty: true });

const loading = ref(false);

function submitForm() {
  v$.value.$validate();
  if (!v$.value.$error) {
    loading.value = true;
    const registration = {
      sessionId: props.session.sessionId,
      firstName: state.firstName,
      lastName: state.lastName,
      emailAddress: state.email,
      phoneNumber: state.phoneNumber,
      zipCode: state.zipCode,
      communicationOptIn: state.signMe,
      acuraOwnership: state.yesOrNo === YesNo.YES,
      acuraModel: state.modelYear.substring(5),
      modelYear: state.modelYear.substring(0, 4),
      brandOpinion: parseInt(state.acuraBrand, 10),
      guestFirstName: isDealer(props.inviteeType) ? '' : state.guestFirstName,
      guestLastName: isDealer(props.inviteeType) ? '' : state.guestLastName,
      uic: getUic.value,
      inviteeType: props.inviteeType,
      dptsid: isDealer(props.inviteeType) ? state.dptsid : '',
    };
    const reservations = async () => {
      try {
        const { data } = await apiManager.reservations.postReservations(registration);
        let message = '';
        switch (data.reservation.status) {
          case 1: message = 'Thank you for registering. We look forward to seeing you at Acura Performance Drive!';
            break;
          default: message = 'Thank you for joining the waitlist. We will notify you if a seat opens up.';
        }
        toast.success(message);
        router.push('/');
      } catch (err) {
        loading.value = false;
        const message = err.response.data.message ?? 'Your registration failed for an unknown reason. Please try again or contact our support team at info@acuraperformancedrive.com.';
        toast.error(message);
      }
    };
    reservations(registration);
  } else {
    toast.error('Please fill out all required fields');
  }
}
</script>
<template>
  <div class="
    bg-[#F7F7F7] p-5 lg:p-14 shadow-lg
  ">
  <div
    class="
    font-AvenirHeavy uppercase
    border-b-[1px] pb-10 border-acura-gray-dark
  ">
    <div
      v-if="props.action !== actions.WAITLIST"
      class="relative lg:absolute right-0 lg:right-14 top-0 lg:top-10
    ">
    <registration-timer v-if="action !== actions.WAITLIST" />
    </div>
      <div>
        <h2 class="text-2xl mb-10 mt-5 lg:mt-10">Location and Time</h2>
        <p class="text-2xl sm:text-3xl lg:text-4xl">
          {{ props.city }}
        </p>
        <p class="sm:text-lg lg:text-xl mb-10">
          {{ formatDate(props.session.startTime) }} | {{ formatTime(props.session.startTime) }}
        </p>
        <router-link
          :to="`/location/${props.locationId}`"
          class="uppercase text-acura-primary
        ">
          Edit location or time
        <font-awesome-icon
          :icon="faChevronRight"
          size="md"
          color="acura-primary"
          class="ml-2"
        />
        </router-link>
      </div>
    </div>
    <form
        @submit.prevent="submitForm"
      >
      <h2 class="font-AvenirHeavy uppercase text-xl lg:text-2xl mt-10 mb-7">
          Personal Information
      </h2>
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-5">
        <the-textfield
          class="mb-12"
          name="first-name"
          label="FIRST NAME*:"
          placeholder="First Name"
          :dirty="v$.firstName.$dirty"
          :errorMessage="v$.firstName.$errors[0]?.$message"
          v-model:value="state.firstName"
        />
        <the-textfield
          name="last-name"
          label="LAST NAME*:"
          placeholder="Last Name"
          :dirty="v$.lastName.$dirty"
          :errorMessage="v$.lastName.$errors[0]?.$message"
          v-model:value="state.lastName"
        />
        <the-textfield
          class="mb-12"
          type="email"
          name="email"
          label="EMAIL*:"
          placeholder="email@webaddress.com"
          :dirty="v$.email.$dirty"
          :errorMessage="v$.email.$errors[0]?.$message"
          v-model:value="state.email"
        />
        <the-textfield
          type="email"
          name="confirm-email"
          label="CONFIRM EMAIL*:"
          placeholder="email@webaddress.com"
          tooltip="Please confirm your email address"
          :dirty="v$.confirmEmail.$dirty"
          :errorMessage="v$.confirmEmail.$errors[0]?.$message"
          v-model:value="state.confirmEmail"
        />
        <the-textfield
          class="mb-12"
          name="phone-number"
          label="PHONE NUMBER*:"
          placeholder="(000) 000-0000"
          tooltip="Pleased provide your full phone number including area code"
          mask="(000) 000-0000"
          maxLength="14"
          :dirty="v$.phoneNumber.$dirty"
          :errorMessage="v$.phoneNumber.$errors[0]?.$message"
          v-model:value="state.phoneNumber"
        />
        <the-textfield
          name="zip-code"
          label="ZIP CODE*:"
          placeholder="00000"
          tooltip="Please provide a five or nine digit zip code"
          mask="00000[-0000]"
          maxLength="10"
          :dirty="v$.zipCode.$dirty"
          :errorMessage="v$.zipCode.$errors[0]?.$message"
          v-model:value="state.zipCode"
        />
        <the-textfield
          v-if="isDealer(props.inviteeType)"
          class="mb-12"
          name="dptsid"
          label="DPTSID*:"
          placeholder="DPTSID"
          :dirty="v$.dptsid.$dirty"
          :errorMessage="v$.dptsid.$errors[0]?.$message"
          v-model:value="state.dptsid"
        />
      </div>
      <fieldset class="mt-12 text-lg">
        <legend class="font-AvenirMed mb-2">
          I would like to receive confirmation and reminders
        </legend>
        <div class="text-acura-gray-darker">
        <the-checkbox
          name="sign"
          label="Sign me up for communications with general information about new models,
          special offers, and Acura stories."
          @input="state.signMe = $event"
        />
          <span v-if="state.signMe">
            <p class="pl-[35px] text-acura-gray-darker">
              By checking this box, I agree to: (1) receive recurring automated marketing
              and non-marketing calls, texts, and emails from American Honda Motor Co., Inc.
              and participating Honda and Acura dealers at any phone numbers and email
              addresses provided above (consent not required to make a purchase, msg &
              data rates apply, reply STOP to opt-out of texts); (2) the
              <a href="https://www.honda.com/privacy/sms-terms.pdf" target="_blank" class="underline font-AvenirBlack">SMS</a>
              Terms (including arbitration provision); and (3) the
              <a href="https://www.honda.com/privacy/privacy-policy.pdf" target="_blank" class="underline font-AvenirBlack">Privacy Policy</a>
              (which describes how Honda collects and uses personal
              information and any privacy rights I may have).
            </p>
          </span>
        </div>
      </fieldset>
      <fieldset class="mt-16">
        <legend class="font-AvenirMed mb-2">
          Do you currently own an Acura vehicle?
        </legend>
        <the-radio
          name="yesOrNo"
          @input="state.yesOrNo = $event"
          :options="[
            { id: 'yes', label: 'Yes', value: 'yes' },
            { id: 'no', label: 'No', value: 'no' },
          ]"
        />
        <div v-if="state.yesOrNo === YesNo.YES">
          <the-textfield
            :name="model-and-model-year"
            label="MODEL AND MODEL YEAR:"
            placeholder="YYYY Model"
            :dirty="v$.modelYear.$dirty"
            :errorMessage="v$.modelYear.$errors[0]?.$message"
            v-model:value="state.modelYear"
            class="mt-5"
            mask="0000 C"
          />
        </div>
      </fieldset>
      <fieldset class="mt-16">
        <legend class="font-AvenirMed mb-2">
          What is your opinion of the Acura brand?
        </legend>
        <the-radio
          name="acuraBrand"
          @input="state.acuraBrand = $event"
          :options="[
            { id: 'veryFavorable', label: 'Very Favorable', value: 0 },
            { id: 'favorable', label: 'Favorable', value: 1 },
            { id: 'neutral', label: 'Neutral', value: 2 },
            { id: 'unfavorable',label: 'Unfavorable', value: 3 },
            { id: 'veryUnfavorable', label: 'Very Unfavorable', value: 4 },
          ]"
        />
      </fieldset>
      <p
        class="mt-16 leading-10"
        :class="isDealer(props.inviteeType) ? 'mb-10' : 'mb-0'"
      >
        <i>
          <b>Please Note:</b> Must be 18 years of age or older with  a valid driver's
          license to participate in the driving activities.<br />
            Please leave weapons, alcohol, and controlled substances at
            home to maintain a safe driving environment.
        </i>
      </p>
      <div>
      <h2
        v-if="!isDealer(props.inviteeType)"
        class="
          font-AvenirHeavy
          uppercase
          text-xl
          lg:text-2xl
          mt-12
          mb-10
        ">
          Guest Information
      </h2>
      <div
        v-if="!isDealer(props.inviteeType)"
        class="grid grid-cols-1 lg:grid-cols-2 gap-y-7 lg:gap-y-12 gap-x-5">
        <the-textfield
          class="mb-12"
          name="guest-first-name"
          label="GUEST FIRST NAME:"
          placeholder="Guest First Name"
          :dirty="v$.guestFirstName.$dirty"
          :errorMessage="v$.guestFirstName.$errors[0]?.$message"
          v-model:value="state.guestFirstName"
        />
        <the-textfield
          name="guest-last-name"
          label="GUEST LAST NAME:"
          placeholder="Guest Last Name"
          :dirty="v$.guestLastName.$dirty"
          :errorMessage="v$.guestLastName.$errors[0]?.$message"
          v-model:value="state.guestLastName"
        />
      </div>
      <button
        @click.prevent="submitForm()"
        class="
          uppercase text-white py-3
          lg:px-8 w-full lg:w-auto mb-10
          bg-acura-primary
          disabled:bg-acura-gray-dark
          disabled:cursor-not-allowed
        "
        :disabled="loading"
        >
        Submit
        <font-awesome-icon
          v-if="loading"
          :icon="faSpinner"
          class="ml-2 animate-spin"
        />
      </button>
      <p>
        To learn more about how we collect and use
        personal information, please read our
        <a href="https://www.honda.com/privacy/privacy-policy.pdf" target="_blank" class="underline font-AvenirBlack">Privacy Policy</a>.
      </p>
    </div>
    </form>
  </div>
</template>
