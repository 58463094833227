<template>
  <div class="overflow-hidden w-32 lg:w-[22.125rem]">
    <div
      class="
        bg-acura-primary
        slash-clip
        w-[4.75rem]
        h-11
        lg:w-[13.75rem] lg:h-[7.625rem]
      "
    ></div>
    <div
      class="
        bg-black
        slash-clip
        w-[4.75rem]
        h-11
        absolute
        top-0
        left-[2.875rem]
        lg:w-[13.75rem] lg:h-[7.625rem] lg:left-[8.375rem]
      "
    ></div>
  </div>
</template>

<script>
export default {
  name: 'HeroSlashes',
};
</script>
