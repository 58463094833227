<template>
  <div class="hero-clip bg-acura-primary h-[840px]">
    <div
      class="
        hero-clip
        flex
        lg:items-center
        h-[820px]
        bg-mobile
        lg:bg-desktop
        bg-no-repeat bg-section-hero-mobile
        lg:bg-section-hero
        px-8
        py-24
        lg:py-0 lg:px-24
        [background-size:100px,90%,auto,cover]
        lg:[background-size:auto,auto,auto,auto,cover,cover,cover]
        box-border
      "
    >
      <div class="basis-1/1 xl:basis-1/2 lg:mt-20">
        <img
          src="@/assets/img/acura-logo-vector.svg"
          alt="Acura logo"
          class="w-[10.813rem] lg:w-[14.375rem] invert mb-3"
        />
        <h1
          class="
            font-AcuraBespokeBold
            text-white text-[2.5rem]
            tracking-wider
            leading-none
            lg:text-7xl
            uppercase
            mb-4
            lg:mb-16
          "
          aria-label="Acura Performance Drive"
        >
          Performance Drive
        </h1>
        <p
          class="
            font-AvenirHeavy
            text-white text-lg
            tracking-[0.036em]
            leading-snug
            lg:text-4xl lg:leading-tight
            max-w-[810px]
          "
        >
        {{ paragraph }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'HeroSection',
  data() {
    return {
      paragraph: 'Unleash your performance DNA. Experience the thrill of Precision Crafted Performance behind the wheel of our newest sport vehicles.',
    };
  },
};
</script>
