import { baseCRUDAPI } from './base';

export default class Reservations extends baseCRUDAPI {
  constructor(axiosObject) {
    super(axiosObject, 'Reservations');
  }

  async getLocations({ inviteeType } = {}) {
    return this.axiosObject.get(
      `${this.apiBaseName}/Locations`,
      {
        params: {
          inviteeType,
        },
      },
    );
  }

  async getAllLocations() {
    return this.axiosObject.get(`${this.apiBaseName}/Locations`);
  }

  async getSessions({ inviteeType, locationId }) {
    return this.axiosObject.get(
      `${this.apiBaseName}/Sessions`,
      {
        params: {
          locationId,
          inviteeType,
        },
      },
    );
  }

  async postReserveRegistration({ uic, sessionId }) {
    return this.axiosObject.post(
      `${this.apiBaseName}/ReserveRegistration`,
      null,
      {
        params: {
          uic,
          sessionId,
        },
      },
    );
  }

  async postReservations(data) {
    return this.axiosObject.post(`${this.apiBaseName}`, data, {
      headers: {
        'Content-type': 'application/json',
      },
    });
  }

  async postAddGuest({
    authToken,
    uic,
    guestFirstName,
    guestLastName,
  }) {
    return this.axiosObject.post(
      `${this.apiBaseName}/AddGuest`,
      null,
      {
        headers: {
          authToken,
        },
        params: {
          uic,
          guestFirstName,
          guestLastName,
        },
      },
    );
  }

  async putAddTime({ uic, sessionId }) {
    return this.axiosObject.put(
      `${this.apiBaseName}/ReserveRegistration/AddTime`,
      null,
      {
        params: {
          uic,
          sessionId,
        },
      },
    );
  }

  async cancel({ id }) {
    return this.axiosObject.delete(
      `${this.apiBaseName}/${id}/cancel`,
    );
  }
}
