<template>
  <div>
    <h2
      class="
        font-AvenirHeavy
        text-4xl
        uppercase
        after:content-['']
        after:bg-acura-primary
        after:block
        after:h-2
        after:w-14
        after:mb-8
      "
    >
      Need Help?
    </h2>
    <p
      class="
        font-AvenirRoman
        tracking-[0.036em]
        text-lg
        lg:text-xl
        mb-12
        max-w-xl
      "
    >
      If you can't find the answer you're looking for please contact us.
    </p>
    <a
      href="mailto:info@acuraperformancedrive.com"
      title="Click here to email us"
      class="
        bg-acura-primary
        text-white
        uppercase
        flex
        h-16
        lg:max-w-[180px]
        items-center
        justify-center
        font-AvenirHeavy
        tracking-[0.036em]
        text-xl
      "
      >contact us</a
    >
  </div>
</template>
