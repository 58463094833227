<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useMeta } from 'vue-meta';

const route = useRoute();

const store = useStore();

const locations = computed(() => store.state.locations.locations);

const invisibleRoutes = ['/event-check-in', '/url-generator'];

useMeta({
  robots: 'noindex',
});
</script>
<template>
  <metainfo
    v-if="invisibleRoutes.includes(route.path)"
  />
  <div class="
  bg-acura-primary
  text-white
    sticky
    top-0
    left-0
    right-0
    py-3
    text-center
    font-bold
    text-[1.375rem]
    z-[1000]
  ">
    This site was for an event in 2022 that is now over.
  </div>
  <header class="container mx-auto">
    <a
      class="
        bg-acura-primary
        text-white
        font-AvenirHeavy
        left-1/2
        p-1
        absolute
        -translate-y-full
        focus:translate-y-0
        transition-transform
        duration-300
      "
      href="#main"
      >Skip to main content</a
    >
    <primary-navbar :locations="locations" />
  </header>
  <main id="main" class="container mx-auto flex-1 overflow-hidden">
    <router-view :key="route.fullPath" />
  </main>
  <the-footer :locations="locations" class="container mx-auto" />
</template>
