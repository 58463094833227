<template>
  <footer class="lg:px-24 py-10 bg-black text-white">
    <div class="flex justify-between lg:items-center flex-col lg:flex-row">
      <div
        class="
          lg:basis-[50%]
          xl:basis-[66%]
          2xl:basis-[70%]
          xl:flex
          lg:items-center
        "
      >
        <div
          class="
            xl:border-r xl:border-acura-gray-medium
            px-6
            lg:px-0 lg:py-10 lg:pr-10
          "
        >
          <router-link to="/">
            <img
              alt="Acura logo"
              src="@/assets/img/acura-logo-vector.svg"
              class="w-[12.5rem] lg:w-[17.8125rem] invert"
            />
          </router-link>
        </div>
        <div class="py-10 lg:py-0 pl-6 lg:pl-0 xl:pl-10">
          <h2 class="font-AvenirHeavy text-2xl mb-2">Support</h2>
          <p class="text-base lg:text-2xl">
            Email us at
            <a
              href="mailto:info@acuraperformancedrive.com"
              class="underline hover:text-acura-gray-dark"
            >
              info@acuraperformancedrive.com
            </a>
          </p>
        </div>
      </div>
      <div
        class="
          lg:basis-[50%]
          xl:basis-[34%]
          2xl:basis-[30%]
          lg:flex lg:justify-between
        "
      >
        <div
          class="
            py-10
            lg:py-0
            pl-6
            lg:pl-0 lg:border-0
            border-t border-acura-gray-medium
          "
        >
          <h2 class="font-AvenirHeavy text-2xl mb-6 lg:mb-0">Resources</h2>
          <ul
            class="
              grid grid-cols-2
              lg:grid-cols-1
              grid-rows-2 grid-flow-col
              lg:grid-flow-row
              text-lg
              leading-loose
            "
          >
            <li>
              <router-link to="/" class="hover:text-acura-gray-dark">
                Home
              </router-link>
            </li>
            <li>
              <router-link
                to="/event-information"
                class="hover:text-acura-gray-dark"
              >
                Event Information
              </router-link>
            </li>
            <li>
              <router-link to="/faqs" class="hover:text-acura-gray-dark">
                FAQs
              </router-link>
            </li>
            <li>
              <a
                href="https://acura.com"
                target="_blank"
                class="hover:text-acura-gray-dark"
              >
                Acura.com
              </a>
            </li>
          </ul>
        </div>
        <div
          class="
            py-10
            lg:py-0
            pl-6
            lg:pl-0 lg:border-0
            border-t border-acura-gray-medium
            basis-[56%]
          "
        >
          <h2 class="font-AvenirHeavy text-2xl mb-6 lg:mb-0">
            Location<span :class="{ hidden: locations.length === 1 }">s</span>
          </h2>
          <ul
            class="
              grid grid-cols-2
              lg:grid-cols-1
              grid-rows-3 grid-flow-col
              lg:gap-x-4
              text-lg
              leading-loose
            "
          >
            <li v-for="location in locations" :key="location.locationId">
              <router-link
                :to="{ path: `/location/${location.locationId}` }"
                class="hover:text-acura-gray-dark"
              >
                {{ location.linkName }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="
        pt-10
        lg:pt-0
        pl-6
        lg:pl-0
        border-t border-acura-gray-medium
        lg:mt-14
      "
    >
      <ul
        class="
          grid grid-cols-2
          lg:grid-cols-1
          grid-rows-2
          lg:grid-rows-1
          grid-flow-col
          gap-y-6
          lg:gap-y-4 lg:gap-x-4 lg:mt-4
          text-xs
          lg:text-sm
        "
      >
        <li><p class="order-1">© 2022 Jackson Dawson</p></li>
        <li class="order-2">
          <a
            href="https://www.honda.com/privacy/privacy-policy.pdf"
            target="_blank"
            class="hover:text-acura-gray-dark"
          >
            Privacy Policy
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'TheFooter',
  props: {
    locations: Array,
  },
};
</script>
