<script setup>
import { ref, onMounted } from 'vue';
import IMask from 'imask';

const props = defineProps({
  type: { String, default: 'text' },
  name: { String, required: true },
  label: { String, required: true },
  placeholder: { String, required: true },
  tooltip: String,
  value: { String, Number, required: true },
  mask: { String, Number },
  maxLength: Number,
  min: Number,
  max: Number,
  dirty: Boolean,
  errorMessage: String,
});

const input = ref(null);

onMounted(() => {
  if (props.mask) {
    IMask(input.value, {
      mask: props.mask,
      pattern: 'C',
      blocks: {
        C: {
          mask: /^.+$/,
        },
      },
    });
  }
});
</script>
<template>
  <div class="group block relative">
    <label :for="`textfield--${props.name}`"
    class="
      block
      font-AvenirMed
      text-sm
      lg:text-base
    ">
    <span
      v-if="props.label"
      class="input-label mb-2 block"
      >
      {{ props.label }}
    </span>
    <span
      v-if="props.tooltip"
      class="
        absolute
        right-[0]
        invisible
        group-hover:opacity-100
        transition-opacity
        bg-black/80
        p-1
        text-white
        rounded
        text-xs
        -mt-8
        before:content-['']
        before:absolute
        before:top-[100%]
        before:left-[80%]
        before:w-0
        before:h-0
        before:border-t-8
        before:border-t-black/80
        before:border-x-8
        before:border-x-transparent
        group-hover:visible
        ">
      <p
        class="
        ">{{ props.tooltip }}</p>
    </span>
    <input
      class="
        block
        p-3
        w-full
        outline-none
        bg-acura-gray-light
        border-2
        focus:border-black
      "
      :class="
        props.errorMessage ?
          'border-acura-primary focus:border-acura-primary'
          : 'border-acura-gray-medium'
        "
      :type="props.type"
      ref="input"
      :id="`textfield--${props.name}`"
      :name="`textfield--${props.name}`"
      :placeholder="props.placeholder"
      :tooltip="props.tooltip"
      :value="value"
      :maxLength="props.maxLength"
      :max="max"
      :min="min"
      :dirty="props.dirty"
      @input="$emit('update:value', $event.target.value)"
    />
    <small
      v-if="props.errorMessage"
      class="text-acura-primary">
      {{ props.errorMessage }}
    </small>
  </label>
  </div>
</template>
