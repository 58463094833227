import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const connectionString = process.env.VUE_APP_INSIGHTS_CONNECTION_STRING || '';

const analytics = new ApplicationInsights({
  config: {
    connectionString,
  },
});

analytics.loadAppInsights();

export default analytics;
