<script setup>
import apiManager from '@/api';
import FaqsDisplay from '@/components/faqsDisplay/FaqsDisplay.vue';
import isNil from '@/utils/isNil';
import { useStore } from 'vuex';
import { computed, ref, watch } from 'vue';

const store = useStore();
const uic = computed(() => store.state.user.uic);

const faqs = ref([]);

const loadFAQ = async (uic_) => {
  const { data } = await apiManager.home.faqs({ uic: uic_ });
  faqs.value = data;
};

if (!isNil(uic.value)) {
  loadFAQ(uic.value);
}

watch(uic, () => {
  loadFAQ(uic.value);
});

</script>
<template>
  <faqs-display :faqs="faqs" />
</template>
