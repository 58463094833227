import { getTitleByUIC, getTitle, getUserTypeByUIC } from '@/utils/userType';
import UserTypes from '@/types/userTypes';
import apiManager from '@/api';

const isType = (getters) => (userType) => getters.userType === getTitle(userType);

const storageItemName = `AcuraRideAndDriveData-${process.env.NODE_ENV}`;

const module = {
  namespaced: true,
  state: () => ({
    uic: undefined,
    inviteeType: undefined,
    isDealer: false,
    isEnthusiast: false,
  }),
  mutations: {
    setUIC(state, uic) {
      state.uic = uic;
    },
    setInviteeType(state, inviteeType) {
      state.inviteeType = inviteeType;
    },
    setData(state, data) {
      state.uic = data.uic;
      state.inviteeType = data.inviteeType;
      localStorage.setItem(storageItemName, JSON.stringify(data));
    },
  },
  getters: {
    userType(state) {
      return getTitleByUIC(state.uic);
    },
    isMedia(state, getters) {
      return isType(getters)(UserTypes.MEDIA);
    },
    isVIP(state, getters) {
      return isType(getters)(UserTypes.VIP);
    },
    isConsumer(state, getters) {
      return isType(getters)(UserTypes.CONSUMER);
    },
    isEnthusiast(state, getters) {
      return isType(getters)(UserTypes.ENTHUSIAST);
    },
    isDealer(state, getters) {
      return isType(getters)(UserTypes.DEALER);
    },
  },
  actions: {
    async getStoredData({ state }) {
      if (state.uic && state.inviteeType) {
        return {
          uic: state.uic, inviteeType: state.inviteeType,
        };
      }
      const storedData = localStorage.getItem(storageItemName);
      if (storedData) return JSON.parse(storedData);
      return undefined;
    },
    async validateUIC(_, { uic, isDealer = false, isEnthusiast = false }) {
      if (isDealer) {
        // If a uic is provided, check that it is a dealer uic, otherwise discard it
        const dealerUIC = getUserTypeByUIC(uic) === UserTypes.DEALER ? uic : undefined;
        const { data } = await apiManager.home.uicDealer(dealerUIC);
        return data;
      }
      if (isEnthusiast) {
        // If a uic is provided, check that it is a enthusiast uic, otherwise discard it
        const enthusiastUIC = getUserTypeByUIC(uic) === UserTypes.ENTHUSIAST ? uic : undefined;
        const { data } = await apiManager.home.uicEnthusiast(enthusiastUIC);
        return data;
      }
      if (uic && getUserTypeByUIC(uic)) { // if uic is of a valid type
        try {
          // try validating the provided uic
          const { data } = await apiManager.home.uic(uic);
          return data;
        } catch (e) {
          // if the provided uic fails validation, request a new uic
          const { data } = await apiManager.home.uic();
          return data;
        }
      } else {
        // if no uic or invalida, request a new uic
        const { data } = await apiManager.home.uic();
        return data;
      }
    },
    async initialUIC({ commit, dispatch, state }, { uic, isDealer, isEnthusiast }) {
      // returns uic from state if no new uic or new uic matches uic in state
      if ((!uic && state.uic) || (state.uic && state.uic === uic)) {
        return {
          uic: state.uic, inviteeType: state.inviteeType,
        };
      }
      const storedData = await dispatch('getStoredData');
      const initialUIC = uic || storedData?.uic;
      const data = await dispatch('validateUIC', { uic: initialUIC, isDealer, isEnthusiast });
      commit('setData', data);
      return data;
    },
  },
};

export default module;
