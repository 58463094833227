<template>
  <div class="
    section
    bg-[url('assets/img/section-dot-pattern.svg')]
    bg-right-bottom
    bg-no-repeat
    pb-8
    p-4
  ">
    <div class="container mx-auto mb-10 sm:mb-16">
      <h1 class="
        font-AcuraBespokeRegular
        lg:text-center
        text-5xl
        font-medium
        uppercase
        mb-2
        "
        aria-label="Acura Locations"
      >
        {{ cards?.length > 1 ? 'Locations' : 'Location' }}
      </h1>
      <p class="
        font-AvenirRoman
        lg:text-center
        text-lg
        mb-10
        lg:mb-14
      ">
        {{
          cards?.length > 1 ? 'Click on a location to begin registration.'
          : 'Click below to begin registration.'
        }}
      </p>
      <div
        class="
          cards
          grid
          grid-cols-1
          lg:gap-[1px]
          lg:px-24
        "
        :class="cards?.length === 3 ? 'xl:grid-cols-3'
          :cards?.length === 2 ? 'xl:grid-cols-2'
          :cards?.length === 1 ? 'xl:grid-cols-1'
          : 'xl:grid-cols-1'
        ">
        <LocationCard
          v-for="card in cards" :key="card"
          :img="card.img"
          :path="card.path"
          :altText="card.altText"
          :ariaText="card.ariaText"
          :video="card.video"
          :location="card.city"
          :date="card.dates"
          :startTime="card.startTime"
          :endTime="card.endTime"
          :isOne="cards?.length === 1"
        />
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store';
import LocationCard from './LocationCard.vue';
import imageChicago from '../../assets/img/chicago-still.jpg';
import cardVideoChicago from '../../assets/video/chicago.mp4';
import imageNewYork from '../../assets/img/ny-still.jpg';
import cardVideoNewYork from '../../assets/video/ny.mp4';
import imageMiami from '../../assets/img/miami-still.jpg';
import cardVideoMiami from '../../assets/video/miami.mp4';
import imageDallas from '../../assets/img/dallas-still.jpg';
import cardVideoDallas from '../../assets/video/dallas.mp4';
import imageLosAngeles from '../../assets/img/huntington-beach-still.jpg';
import cardVideoLosAngeles from '../../assets/video/huntington-beach.mp4';

export default {
  components: {
    LocationCard,
  },
  name: 'SectionLocation',
  data() {
    return {
      infos: [
        {
          locationId: 1,
          img: imageChicago,
          video: cardVideoChicago,
          path: '/location/1',
          altText: 'aerial view of the Chicago city center',
          ariaText: 'Chicago location page',
        },
        {
          locationId: 2,
          img: imageNewYork,
          video: cardVideoNewYork,
          path: '/location/2',
          altText: 'aerial view of the New York city center',
          ariaText: 'New York location page',
        },
        {
          locationId: 3,
          img: imageMiami,
          video: cardVideoMiami,
          path: '/location/3',
          altText: 'aerial view of the Miami city center',
          ariaText: 'Miami location page',
        },
        {
          locationId: 4,
          img: imageDallas,
          video: cardVideoDallas,
          path: '/location/4',
          altText: 'aerial view of the Dallas city center',
          ariaText: 'Dallas location page',
        },
        {
          locationId: 5,
          img: imageLosAngeles,
          video: cardVideoLosAngeles,
          path: '/location/5',
          altText: 'aerial view of the Los Angeles city center',
          ariaText: 'Los Angeles location page',
        },
      ],
    };
  },
  computed: {
    cards() {
      const data = store.state.locations.locations;
      const newCards = data.map((x) => ({
        ...this.infos.find((y) => y.locationId === x.locationId),
        ...x,
      }));
      return newCards;
    },
  },
};
</script>
