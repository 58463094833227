<script setup>
/* eslint-disable vuejs-accessibility/media-has-caption */
import { ref, onMounted } from 'vue';

defineProps({
  videoFiles: Object,
  trackSrc: String,
  track: String,
});

const video = ref(null);
const isPlaying = ref(false);

const play = () => video.value.play();

const pause = () => video.value.pause();

onMounted(() => {
  video.value.addEventListener('playing', () => { isPlaying.value = true; });
  video.value.addEventListener('pause', () => { isPlaying.value = false; });
});

</script>
<template>
<video
  ref="video"
  class="
    w-full md:w-full
    h-full
    object-cover
    object-bottom
  "
  autoplay loop muted playsinline
>
  <source v-for="(file, index) in videoFiles" :key="index" :src="file.src" :type="file.type" />
  <track kind="captions" :src="trackSrc" srclang="en" />
  Sorry, your browser doesn't support embedded videos.
</video>
<div class="controls w-full h-[2.25rem] z-20 relative bottom-[2.25rem] text-white">
  <button v-if="!isPlaying"
    aria-label="Play background video"
    @click="play"
    class="
      ml-4
      h-full
      cursor-pointer
    "
  >></button>
  <button v-if="isPlaying" @click="pause"
    aria-label="Pause background video"
    class="
      ml-4
      h-full
      cursor-pointer
    ">||</button>
</div>
</template>
